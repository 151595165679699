import React from 'react';
import {
    Avatar,
    Badge,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Popover,
    Toolbar,
    Tooltip,
    Typography,
    withStyles
} from '@material-ui/core';
import {logout} from "../actions/AuthActions";
import {fade} from '@material-ui/core/styles/colorManipulator';
import connect from "react-redux/es/connect/connect";
import MenuIcon from '@material-ui/icons/Menu'
import TranslateIcon from '@material-ui/icons/Translate';
import InputIcon from '@material-ui/icons/Input';
import {CORE_API, DEAFULT_AVATAR_CORE_SERVER} from "../api/Endpoints";
import {profile} from "../styles/CommonStyles";
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import {setLanguage, strings} from "../i18n/Strings";
import {setLang} from "../actions/salaryActions";
import {ThemeContext} from "../App";
import {BlockPicker} from 'react-color';

const styles = theme => ({
    root: {
        width: '100%',
    },
    typography: {
        padding: theme.spacing(2),
        backgroundColor: '#fbfff9'
    },
    span: {
        paddingLeft: '5px',
        paddingRight: '3px',
        paddingTop: '4px',
        fontSize: '0.875rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -21,
        marginRight: 20,
    },
    profilName: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px'
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(9),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    pdf_download: {
        marginLeft: 30,
        float: 'right',
        marginTop: 6,
        textDecoration: 'none',
        color: 'white',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing(10),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    fab1: {
        color: 'rgb(0, 71, 171)',
        width: 30,
        height: 30,
        cursor: 'pointer'
    },
    fab2: {
        color: '#00b6c2',
        width: 30,
        height: 30,
        cursor: 'pointer'
    }, fab3: {
        color: '#11998e',
        width: 30,
        height: 30,
        cursor: 'pointer'
    }, fab4: {
        color: 'rgb(248, 7, 89)',
        width: 30,
        height: 30,
        cursor: 'pointer'
    }, fab5: {
        color: 'rgb(243 189 74)',
        width: 30,
        height: 30,
        cursor: 'pointer'
    }, fab6: {
        color: '#fc00ff',
        width: 30,
        height: 30,
        cursor: 'pointer'
    }
});

export const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const blockStyle = {
    default: {
        input: {
            display: 'none',
        },
        card: {
            backgroundColor: '#fafafa'
        }
    }
};

class MainToolBar extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            anchorEl: null,
            color: 'rgb(0, 71, 171)',
            anchorEll: null,
            lang: props.auth.profile.countryCode === 'PT' ? 'PT' : 'FR',
            languages: props.auth.profile.countryCode === 'PT' ? ['PT','FR', 'EN', 'ES'] : ['FR', 'EN', 'PT', 'ES']
        };
    }

    render() {
        const {
            _TRANSLATE,_themeChange,_logOut} = strings.title;
        const {classes, handleDrawerToggle, dispatch, handleState} = this.props;
        const {userName, firstName, lastName} = this.props.auth.profile;
        const {bigAvatar, padd_top_10} = profile;
        const {anchorEl, anchorEll, languages} = this.state;

        const handleClose = () => {
            this.setState({
                anchorEl: null
            })
        };
        const handleChange = async (language) => {
            setLanguage(language.startsWith('ES') ? 'ES' : language.startsWith('FR') ? 'FR' :
                language.startsWith('PT') ? 'PT' : 'EN');
            this.setState({
                lang: language.startsWith('ES') ? 'ES' : language.startsWith('FR') ? 'FR' : language.startsWith('PT') ?
                    'PT' : 'EN',
                languages: language.startsWith('FR') ? ['EN', 'FR', 'PT', 'ES'] : ['FR', 'EN', 'PT', 'ES'],
                anchorEl: null
            });
            dispatch(setLang(language.startsWith('ES') ? 'ES' : language.startsWith('FR') ? 'FR' :
                language.startsWith('PT') ? 'PT' : 'EN'))
        };

        const handleClick = (event) => {
            this.setState({
                anchorEl: event.currentTarget
            })
        };

        const handlePopoverOpen = (event) => {
            this.setState({
                anchorEll: event.currentTarget
            });
        };

        const handlePopoverClose = () => {
            this.setState({
                anchorEll: null
            });
        };

        const open = Boolean(anchorEll);
        const id = open ? 'simple-popover' : undefined;

        return (
            <ThemeContext.Consumer>
                {context => (
                    <div className={classes.root}>
                        <Toolbar>
                            <IconButton
                                style={{marginBottom: "10px"}}
                                onClick={handleDrawerToggle}
                                className={classes.menuButton} color="inherit" aria-label="Open drawer">
                                <MenuIcon/>
                            </IconButton>
                            <Typography style={{fontFamily: "Varela Round", paddingBottom: "8px"}}
                                        className={classes.title} variant="h6" color="inherit" noWrap>
                                MyPAY
                            </Typography>
                            <div className={classes.grow}/>
                            <div style={{marginRight: "-25px", marginTop: "-11px"}} className={classes.sectionDesktop}>
                                <Typography style={{fontFamily: "Varela Round"}}
                                            className={classes.profilName} variant="h6" color="inherit" noWrap>
                                    {firstName} {lastName}
                                </Typography>
                                <Tooltip title={`${firstName} ${lastName}`} aria-label="add">
                                    <IconButton
                                        style={{marginRight: '-3px'}}
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        color="inherit"
                                    >
                                        <StyledBadge
                                            overlap="circle"
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            variant="dot"
                                        >
                                            <Avatar
                                                style={Object.assign({}, bigAvatar, padd_top_10)}
                                            >
                                                <img style={{height: 52, marginTop: 10}}
                                                     src={`${CORE_API}/${DEAFULT_AVATAR_CORE_SERVER}/${userName}`}
                                                     alt=""/>

                                            </Avatar>
                                        </StyledBadge>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={_TRANSLATE} aria-label="add">
                                    <TranslateIcon onClick={handleClick}
                                                   style={{cursor: 'pointer', marginTop: 25, marginLeft: 11}}/>
                                </Tooltip>

                                <Tooltip title={_themeChange} aria-label="add">
                                    <FormatColorFillIcon onClick={handlePopoverOpen}
                                                         aria-describedby={id}
                                                         style={{cursor: 'pointer', marginLeft: 20, marginTop: 25}}
                                    />
                                </Tooltip>

                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEll}
                                    onClose={handlePopoverClose}
                                    PaperProps={{
                                        style: {
                                            paddingTop: 28,
                                            marginLeft: 12,
                                            backgroundColor: 'transparent',
                                            boxShadow: 'inherit'
                                        }
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <BlockPicker color={context.selectedTheme.color}
                                                 styles={blockStyle}
                                                 colors={[
                                                     "#C1436D", '#1a909e', "#1181B2", '#0472b4', '#c54b8c', "#4FA8C2",
                                                     "#d76b65", '#217a57', '#5cb7b7', "#215086", "#faa342", "#623979", "#cea253",
                                                     "#0D9EDF", "#259B9A", "#D48166", "#EE7879", "#7D3780", "#FFCF43"
                                                     , "#B65741"
                                                 ]}
                                                 onChange={color => context.setCookie(color.hex)}/>
                                </Popover>

                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                                    transformOrigin={{vertical: "top", horizontal: "right"}}
                                    PaperProps={{
                                        style: {
                                            width: 150,
                                        }
                                    }}
                                    disableAutoFocus
                                    disableAutoFocusItem
                                >
                                    {languages && languages.map(language =>
                                        <MenuItem onClick={() => handleChange(language)}>
                                            <Grid container spacing={1}>
                                                <Grid style={{paddingLeft: '3px', paddingTop: "8px"}} item xs={6}>
                                                    <span style={{
                                                        paddingLeft: '5px',
                                                        fontFamily: 'Varela Round',
                                                        fontSize: '13px'
                                                    }}>{language}</span>
                                                </Grid>
                                            </Grid>
                                        </MenuItem>
                                    )}
                                </Menu>
                                {/*<Tooltip title="Change" aria-label="add">*/}
                                {/*    <ColorLensIcon*/}
                                {/*        aria-describedby={id}*/}
                                {/*        height="30px"*/}
                                {/*        width="30px"*/}
                                {/*        onClick={handlePopoverOpen}*/}
                                {/*        style={{*/}
                                {/*            marginTop: '25px',*/}
                                {/*            marginLeft: '18px',*/}
                                {/*            color: '#fff',*/}
                                {/*            cursor: 'pointer'*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</Tooltip>*/}
                                <Tooltip title={_logOut} aria-label="add">
                                    <InputIcon style={{cursor: 'pointer', margin: 25}} onClick={() => {
                                        logout()
                                    }}/>
                                </Tooltip>
                            </div>
                        </Toolbar>
                    </div>
                )}
            </ThemeContext.Consumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {i18n: state.i18n,
        auth: state.auth}
};
export default withStyles(styles)(connect(mapStateToProps)(MainToolBar));
