import React, {useEffect} from 'react';
import {Box, Grid} from "@material-ui/core";
import Ellipsis from "../../assets/svg/ellipsis.svg";
import {ThemeContext} from "../../App";
import {connect, useDispatch} from "react-redux";
import {getRulesAll} from "../../actions/LauncherActions";
import {strings} from "../../i18n/Strings";

const ListRegulationComponent = ({regulations}) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRulesAll());
    }, []);

    const {TITLE} = strings.REGULATIONS.LIST_REGULATION;


    return (
        <ThemeContext.Consumer>
            {context => (
                <div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <h1 style={{marginTop: 1, color: context.selectedTheme.color, fontWeight: 'normal'}}>
                                {TITLE}
                            </h1>
                        </Grid>
                    </Grid>
                    <hr color="#f5f5f5"/>
                    <br/><br/>
                    <Grid container spacing={2}>
                        {regulations && regulations.map((rule) => (
                            <Grid item xs={3}>
                                <Box style={{
                                    cursor: 'pointer', padding: 20, height: 150, border: 'solid 1px #1181b2'
                                }}>
                                    <img src={Ellipsis}
                                         style={{position: 'relative', top: 0, left: 10, float: 'right'}}
                                         height={30} alt=""
                                    />
                                    <span style={{fontSize: 14, fontFamily: 'Varela Round'}}>{rule.description}</span>
                                    <br/> <br/>
                                    <div style={{
                                        backgroundColor: context.selectedTheme.color,
                                        color: '#FFF',
                                        padding: 5,
                                        border: 'solid 1px #fff',
                                        borderRadius: 25,
                                        float: 'left',
                                    }}>
                                        {rule.nom}
                                    </div>
                                    <br/>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        top: 40,
                                        position: 'relative'
                                    }}>
                                        <span style={{fontSize: 12, fontFamily: "Varela Round"}}>
                                            {rule.dateCreation && rule.dateCreation.substr(0, 10)}
                                    </span>

                                    </div>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            )}
        </ThemeContext.Consumer>
    );
};

const mapStateToProps = state => {
    return {
        regulations: state.launcherReducer.regulations
    };
}

export default connect(mapStateToProps)(ListRegulationComponent);
