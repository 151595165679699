import {
    ADD_REGULATION,
    ADD_REGULATION_FAIL,
    ADD_REGULATION_SUCCESS,
    GET_ALL_SERVERS,
    GET_ALL_SERVERS_FAIL,
    GET_ALL_SERVERS_SUCCESS, GET_MODULATION_TYPE, GET_MODULATION_TYPE_FAIL, GET_MODULATION_TYPE_SUCCESS
} from "../types/PayrollEngineTypes";

const initialState = {
    modulations: [],
    modulationTypes: [],
    regulations: [],
    loading: false,
    servers: []

};
export default function launcherReducer(state = initialState, action) {
    switch (action.type) {
        case "GET_MODULATION":
            return {
                ...state,
            };
        case 'GET_MODULATION_SUCCESS':
            return {
                ...state,
                modulations: action.data
            };
        case 'GET_MODULATION_FAIL':
            return {
                ...state,
                modulations: []
            };
        case GET_MODULATION_TYPE:
            return {
                ...state,
            };
        case GET_MODULATION_TYPE_SUCCESS:
            return {
                ...state,
                modulationTypes: action.data
            };
        case GET_MODULATION_TYPE_FAIL:
            return {
                ...state,
                modulationTypes: []
            };

        case GET_ALL_SERVERS:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_SERVERS_SUCCESS:
            return {
                ...state,
                loading: false,
                servers: action.result
            };
        case GET_ALL_SERVERS_FAIL:
            return {
                ...state,
                loading: false,
                servers: []
            };
        case ADD_REGULATION:
            return {
                ...state,
                loading: true
            };
        case ADD_REGULATION_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case ADD_REGULATION_FAIL:
            return {
                ...state,
                loading: false
            };
        case 'GET_RULES_SUCCESS':
            return {
                ...state,
                regulations: action.result
            };
        case 'GET_RULES_FAIL':
            return {
                ...state,
                regulations: []
            };
        case 'INIT_FIELDS':
            return {
                ...state,
                modulations: [],
                regulations: [],
            };
        default:
            return state;
    }
};
