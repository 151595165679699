import React, {useEffect} from 'react';
import {Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, withStyles} from "@material-ui/core";
import {ThemeContext} from "../../App";
import {connect, useDispatch} from "react-redux";
import {styles} from "../../styles/CommonStyles";
import {Field, reduxForm} from "redux-form";
import {renderTextField} from "../launcher/Launcher";
import CancelIcon from "@material-ui/icons/Cancel";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircle';
import {getAllCountries, getCountryTypes} from "../../actions/EVsActions";
import {renderSelectPop} from "../../layout/renderComponents";
import {addRegulation} from "../../actions/regulationActions";
import Loader from "../../layout/Loader";
import {strings} from "../../i18n/Strings";

const renderSelectComponent = ({input, label, variant, values, meta: {touched, error}, ...custom}) => (
    <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        label={label}
        variant={variant}
        {...input}
        {...custom}
    >
        <MenuItem value="">
            <em>-- SÉLÉCTIONNER --</em>
        </MenuItem>
        {values && values.map(val =>
            <MenuItem key={val} value={val}>{val}</MenuItem>
        )}
    </Select>
);
const AddRegulationComponent = ({pays, countryTypes, submitting, pristine, handleSubmit, reset, loading}) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllCountries());
        dispatch(getCountryTypes());
    }, []);

    const {_TITLE, _ADD_BUTTON, _INIT_BUTTON} = strings.REGULATIONS.NEW_REGULATION;
    const {_COUNTRIES, _COUNTRY_TYPE, _CODE, _DESCRIPTION, _LABEL, _NAME}
        = strings.REGULATIONS.NEW_REGULATION.FIELDS;

    const onSubmit = async values => {

        console.log("values", values)
        const {code, libelle, countryDTO, countryType, description, nom} = values;
        const payload = {
            code: Number(code),
            showing: true,
            libelle: libelle,
            ruleDTO: {
                countryDTO: {
                    id: countryDTO
                },
                countryType: countryType,
                description: description,
                nom: nom,
                deleted: false
            },
            size: 0,
            type: "S"
        }

        await dispatch(addRegulation(payload));
        reset();
    }

    return (
        <ThemeContext.Consumer>
            {context => (
                <div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <h1 style={{
                                marginTop: 1,
                                color: context.selectedTheme.color,
                                fontWeight: 'normal'
                            }}>{_TITLE}</h1>
                        </Grid>
                    </Grid>
                    <hr color="#f5f5f5"/>
                    <br/><br/>
                    <form style={{paddingTop: '20px', paddingBottom: '20px'}} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Field name="code" type="number" label={_CODE} component={renderTextField}/>
                                <br/><br/>
                            </Grid>
                            <Grid item xs={6}>
                                <Field name="libelle" label={_LABEL} component={renderTextField}/>
                                <br/><br/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Field name="nom" label={_NAME} component={renderTextField}/>
                                <br/><br/>
                            </Grid>
                            <Grid item xs={6}>
                                <Field name="description" label={_DESCRIPTION}
                                       component={renderTextField}/>
                                <br/><br/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl size={"medium"} variant={"outlined"} fullWidth>
                                    <InputLabel>
                                        {_COUNTRIES}
                                    </InputLabel>
                                    <Field name="countryDTO" label={_COUNTRIES} variant={'outlined'}
                                           values={pays} component={renderSelectPop}/>
                                </FormControl>
                                <br/><br/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl size={"medium"} variant={"outlined"} fullWidth>
                                    <InputLabel>
                                        {_COUNTRY_TYPE}
                                    </InputLabel>
                                    <Field name="countryType" label={_COUNTRY_TYPE} variant={'outlined'}
                                           values={countryTypes} component={renderSelectComponent}/>
                                </FormControl>
                                <br/><br/>
                            </Grid>
                        </Grid>

                        <br/><br/>
                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                size={"large"}
                                variant="contained"
                                style={{
                                    color: '#fff',
                                    marginRight: 10,
                                    cursor: 'pointer',
                                    background: context.selectedTheme.color
                                }}
                                onClick={reset}
                                startIcon={<CancelIcon/>}>
                                {_INIT_BUTTON}
                            </Button>
                            <Button size={"large"} variant="contained"
                                    style={{
                                        color: '#fff',
                                        width: 150,
                                        cursor: 'pointer',
                                        background: context.selectedTheme.color
                                    }}
                                    disabled={submitting || pristine}
                                    startIcon={<AddCircleOutlineIcon/>} type="submit">
                                {_ADD_BUTTON}
                            </Button>

                        </Box>
                    </form>

                    <Loader color={context.selectedTheme.color} open={loading}/>
                </div>
            )}
        </ThemeContext.Consumer>
    );
};

const mapStateToProps = state => {
    return {
        regulations: state.launcherReducer.regulations,
        loading: state.launcherReducer.loading,
        pays: state.evsReducer.pays,
        countryTypes: state.evsReducer.countryTypes,
        lang: state.salaryReducer.lang,
    };
}
export default connect(mapStateToProps)(reduxForm({
    form: 'regulations',
    enableReinitialize: true
})(withStyles(styles)(AddRegulationComponent)));
