import React, {useContext, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {strings} from "../../i18n/Strings";
import {Pagination} from "@material-ui/lab";
import {useStyles} from "./style";
import {ThemeContext} from "../../App";
import {getExcludedUsers} from "../../actions/SettingsActions";


const ExcludedUsersComponent = ({data, totalPages, fetchData, searchValue }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const context = useContext(ThemeContext);
    const classes = useStyles(context.selectedTheme);
    const {_deleted, _oui, _non} = strings;
    const {
        _creationDate,
        _email,
        _entryDate,
        _firstname,
        _matricule,
        _lastname
    } = strings.columns.excluderUser;
    const columns = [
        { headerName: _matricule, dataKey: 'userName' },
        { headerName: _lastname, dataKey: 'lastName' },
        { headerName: _firstname, dataKey: 'firstName' },
        { headerName: _email, dataKey: 'email' },
        { headerName: _creationDate, dataKey: 'creationDate' },
        { headerName: _entryDate, dataKey: 'entryDate' },
        { headerName: _deleted, dataKey: 'deleted' },
    ];

    useEffect(() => {
        fetchData(searchValue, 0);
    }, [searchValue]);

    const handleChangePage = async (event, newPage) => {
        setPage(newPage - 1);
        await fetchData(searchValue, newPage - 1);
    };

    return (
        <ThemeContext.Consumer>
            {context => (
                <Paper className={classes.paper}>
                    <TableContainer className={classes.tableContainer}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    {columns.map((column, index) => (
                                        <TableCell className={classes.header} key={index}>{column.headerName}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((row, rowIndex) => (
                                    <TableRow key={rowIndex} className={classes.tableRow}
                                    >
                                        {columns.map((column, colIndex) => (
                                            <TableCell
                                                key={colIndex}
                                                className={classes.cell}
                                            >
                                                {row[column.dataKey] === true ? <div className={classes.deleted}>{_oui}</div> :
                                                    row[column.dataKey] === false ? <div className={classes.notDeleted}>{_non}</div> :
                                                        row[column.dataKey]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{marginTop: "10px",display:"flex",justifyContent:"end"}}>
                        <Pagination
                            count={totalPages}
                            page={page + 1}
                            onChange={handleChangePage}
                            color="primary"
                            shape="rounded"
                            variant="outlined"
                            className={
                                classes.ul
                            }
                        />
                    </div>
                </Paper>
            )}
        </ThemeContext.Consumer>

    );
};

const mapStateToProps = (state) => ({
    data: state.settingReducer.excludedUsersData,
    totalPages: state.settingReducer.excludedUsersPages,
    lang: state.salaryReducer.lang
});

const mapDispatchToProps = {
    fetchData: getExcludedUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExcludedUsersComponent);
