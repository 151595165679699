import React, {useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import {connect} from "react-redux";
import {strings} from "../../i18n/Strings";
import {ThemeContext} from "../../App";
import TextField from "@material-ui/core/TextField";
import {InputAdornment} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ExcludedUsersComponent from "./ExcludedUsersComponent";
import {makeStyles} from "@material-ui/core/styles";
import {theme} from "../../theme/MaterialTheme";
import Button from "@material-ui/core/Button";
import exportIcon from "../../assets/images/export.png";
import {exportExcludedSites, exportExcludedUsers} from "../../actions/SettingsActions";
import ExcludedSitesComponent from "./ExcludedSitesComponent";
import Loader from "../../layout/Loader";

const useStyles = makeStyles({
    root: {
        fontFamily: 'Inter, sans-serif',
        flexGrow: 1,
    },
    title: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '17px',
        fontWeight: 500,
        textAlign: 'left',
    },
    tabs: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderRadius: '8px',
        color: 'rgba(102, 112, 133, 1)',
        width: 'auto',
        borderColor: 'black'
    },
    tab: {
        fontWeight: 600,
        borderRadius: '8px',
        fontSize: '14px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        textTransform: 'capitalize'
    },
    formContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    form: {
        display: 'flex'
    },
    field: (props) => ({
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px',
        marginRight: theme.spacing(1),
        borderRadius: '8px',
        outline: 'none',
        width: '330px',
        height: '50px',
        '&.Mui-focused fieldset': {
            borderColor: `${props.color} !important`  // Border color when focused
        },
    }),
    button: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px',
        marginRight: theme.spacing(1),
        borderRadius: '8px',
        outline: 'none',
        width: '200px',
        background: 'rgb(41, 135, 18) !important',
        height: '50px',
        boxShadow: "none",
        color: "white !important",
        textTransform: 'none',
    },
});


const ExcludedCasesViewerComponent = ({loading, isExporting, exportExcludedUsersDataExcel, exportExcludedSitesDataExcel}) => {
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState('');
    const [value, setValue] = React.useState(0);
    const {_excludedUsers,_excludedSites,_excludedCases,_export} = strings.setting;
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const {
        _matricule,
    } = strings.columns.excluderUser;
    const {
        _sirh,
    } = strings.columns.excluderSite;

    const exportData = () => {
        if (value === 0) {
            exportExcludedUsersDataExcel(searchValue);
        }
        else if (value === 1) {
            exportExcludedSitesDataExcel(searchValue);
        }
    }

    return (
        <ThemeContext.Consumer>
            {context => (
                <div className={classes.root}>
                    <Loader color={context.selectedTheme.color} open={loading}/>
                    <div className={classes.formContainer}>
                            <Typography variant="h6" className={classes.title}
                                        style={{
                                            color: context.selectedTheme.color}}
                            >{_excludedCases}
                            </Typography>
                        <AppBar className={classes.tabs} position="static">
                        <div className={classes.form}>
                            <Tabs value={value} onChange={handleChange} style={{border: '1px solid #D0D5DD', borderRadius: '8px', marginRight: '10px'}}
                                  TabIndicatorProps={{
                                      style: {
                                          display: 'none'
                                      }
                                  }}
                                  aria-label="simple tabs example">
                                <Tab
                                    label={_excludedUsers}
                                    value={0}
                                    className={classes.tab}
                                    style={{
                                        color: value === 0 ? context.selectedTheme.color : 'rgba(102, 112, 133, 1)'
                                    }}
                                />
                                <Tab
                                    label={_excludedSites}
                                    value={1}
                                    className={classes.tab}
                                    style={{
                                        color: value === 1 ? context.selectedTheme.color : 'rgba(102, 112, 133, 1)'
                                    }}
                                />
                            </Tabs>
                            <TextField id="outlined-basic"
                                       InputProps={{
                                           className: classes.field,
                                           endAdornment: (
                                               <InputAdornment position="end">
                                                   <SearchIcon />
                                               </InputAdornment>
                                           )
                                       }}
                                       value={searchValue}
                                       label={value === 0 ? _matricule : _sirh} variant="outlined"
                                       onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <Button variant="contained" disabled={isExporting} className={classes.button} onClick={exportData}>
                                <img style={{position: 'relative', right: '1vh'}} src={exportIcon} width={20} alt="ss"/>
                                {_export}  .xlsx
                            </Button>
                        </div>
                        </AppBar>
                    </div>
                    {value === 0 && <ExcludedUsersComponent searchValue={searchValue}/>}
                    {value === 1 && <ExcludedSitesComponent searchValue={searchValue}/>}
                </div>
            )}
        </ThemeContext.Consumer>
    );
}

const mapStateToProps = (state) => ({
    loading: state.settingReducer.loading,
    isExporting: state.settingReducer.isExporting,
    lang: state.salaryReducer.lang
});

const mapDispatchToProps = {
    exportExcludedUsersDataExcel: exportExcludedUsers,
    exportExcludedSitesDataExcel: exportExcludedSites,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExcludedCasesViewerComponent);
