import React, {useState, useEffect} from 'react';
import {
    Grid,
    Paper,
    withStyles,
    Button,
    FormControl,
    InputLabel,
    Box, Select, MenuItem, Collapse, TextField
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import {connect, useDispatch} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {styles} from "../../styles/CommonStyles";
import moment, {now} from "moment";
import {getUOsByCountryCode, getUsersOfUOs} from "../../actions/EVsActions";
import {Alert, Pagination} from "@material-ui/lab";
import {getModulations, getRulesAll, initFields, launch} from "../../actions/LauncherActions";
import {KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardTimePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {ThemeContext} from "../../App";
import {strings} from "../../i18n/Strings";
import 'animate.css';

const not = (a, b) => {
    return a.filter((value) => b.indexOf(value) === -1);
};
const intersection = (a, b) => {
    return a.filter((value) => b.indexOf(value) !== -1);
};

export const renderDateComponent = ({
                                        input,
                                        label,
                                        maxDate,
                                        minDate,
                                        disabled,
                                        selectedDate,
                                        handleDateChange,
                                        meta: {touched, error},
                                        ...custom
                                    }) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
            <KeyboardDatePicker
                inputVariant="outlined"
                disabled={disabled}
                disableToolbar
                format="dd/MM/yyyy"
                fullWidth
                maxDate={maxDate}
                minDate={minDate}
                margin="normal"
                id="date-picker-inline"
                label={label}
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'changer la date',
                }}
            />
        </Grid>
    </MuiPickersUtilsProvider>
);

export const renderTimeComponent = ({
                                        input,
                                        label,
                                        maxDate,
                                        minDate,
                                        disabled,
                                        selectedTime,
                                        handleTimeChange,
                                        meta: {touched, error},
                                        ...custom
                                    }) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
            <KeyboardTimePicker
                inputVariant="outlined"
                margin="normal"
                fullWidth
                id="time-picker"
                label={label}
                value={selectedTime}
                onChange={handleTimeChange}
                KeyboardButtonProps={{
                    'aria-label': 'change time',
                }}
            />
        </Grid>
    </MuiPickersUtilsProvider>
);

export const renderSelectComponent = ({input, label, variant, values, meta: {touched, error}, ...custom}) => (
    <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        label={label}
        variant={variant}
        {...input}
        {...custom}
    >
        {values && values.length === 0 ? (
                <MenuItem value="">
                    <em>-- SÉLÉCTIONNER --</em>
                </MenuItem>

            ) :
            values.map(val =>
                <MenuItem key={val.id} value={val}>{val.libelle && val.libelle.toUpperCase()}</MenuItem>
            )}

    </Select>
);

export const renderPaysComponent = ({input, label, variant, values, meta: {touched, error}, ...custom}) => (
    <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        label={label}
        variant={variant}
        {...input}
        {...custom}
    >
        <MenuItem value="">
            <em>-- SÉLÉCTIONNER --</em>
        </MenuItem>
        {values && values.map(val =>
            <MenuItem key={val.id} value={val.code}>{val.name}</MenuItem>
        )}
    </Select>
);

export const renderRulesComponent = ({input, label, variant, values, meta: {touched, error}, ...custom}) => (
    <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        label={label}
        variant={variant}
        {...input}
        {...custom}
    >
        <MenuItem value="">
            <em>-- SÉLÉCTIONNER --</em>
        </MenuItem>
        {values && values.map(val =>
            <MenuItem key={val.id} value={val.id}>{val.nom}</MenuItem>
        )}
    </Select>
);

export const renderTextField = ({input, label, meta: {touched, error}, ...custom}) => (
    <TextField fullWidth id="outlined-basic" label={label} size={"medium"} variant="outlined" hintText={label}
               floatingLabelText={label}
               errorText={touched && error}
               {...input}
               {...custom}
    />
);

const validate = values => {
    const errors = {};
    const requiredFields = [
        'titre',
        'modulationTypesDTO',
        'dateDeb',
        'dateFin'
    ];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });

    return errors;
};

const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

const Launcher = ({
                      classes,
                      handleSubmit,
                      reset,
                      modulations,
                      pays,
                      regulations,
                      uos,
                      idUos,
                      pristine,
                      submitting,
                      totalElementUOs
                  }) => {
        const [dateDeb, setDateDeb] = useState(null);
        const [dateFin, setDateFin] = useState(null);
        const [alert, setAlert] = useState(false);
        const [display, setDisplay] = useState(false);
        const [message, setMessage] = useState('');
        const [period, setPeriod] = useState('');
        const [page, setPage] = useState(0);
        const [value, setValue] = useState('');
        const [disableBtn, setDisableBtn] = useState(false);
        const [rows, setRows] = useState(5);
        const [minRows, setMinRows] = useState(5);
        const [maxRows, setMaxRows] = useState(10);
        const [dialog, setDialog] = useState(false);
        const [selectedPays, setSelectedPays] = useState();
        const [checked, setChecked] = useState([]);
        const [open, setOpen] = useState(false);
        const [leftC, setLeftC] = useState([]);
        const [rightC, setRightC] = useState([]);
        const leftChecked = intersection(checked, leftC);
        const rightChecked = intersection(checked, rightC);
        const {
            _startDate, _endDate, _isRequired, _modulation, _reset, _textArea, _label, _title, _validate
            , _verify, _country, _rules
        } = strings.launch;

        const dispatch = useDispatch();

        const handleToggle = (value) => () => {
            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
            setChecked(newChecked);
        };
        const handleAllRight = () => {
            setRightC(rightC.concat(leftC));
            setLeftC([]);
        };
        const handleCheckedRight = () => {
            setRightC(rightC.concat(leftChecked));
            setLeftC(not(leftC, leftChecked));
            setChecked(not(checked, leftChecked));
        };
        const handleCheckedLeft = () => {
            setLeftC(leftC.concat(rightChecked));
            setRightC(not(rightC, rightChecked));
            setChecked(not(checked, rightChecked));
        };
        const handleAllLeft = () => {
            setLeftC(leftC.concat(rightC));
            setRightC([]);
        };

        useEffect(() => {
            dispatch(getRulesAll());
        }, []);

        useEffect(() => {
            if (idUos.length > 0)
                setLeftC(idUos)
        }, [idUos]);

        useEffect(() => {

            if (rightC.length > 0) {
                setOpen(true);
                dispatch(getUsersOfUOs(rightC));
            }

            if (rightC.length === 0) {
                setOpen(false);
            }
        }, [rightC]);

        const handleChangePage = async (event, newPage) => {
            await dispatch(getUOsByCountryCode(newPage - 1, selectedPays));
            setTimeout(() => {
                setPage(newPage - 1);
            }, 2000);
        };

        const handleDateDebChange = (date) => {
            setDateDeb(moment(date).format("YYYY-MM-DD"));
        };

        const handleDateFinChange = (date) => {
            setPeriod(moment(date).month() + 1)
            setDateFin(moment(date).format("YYYY-MM-DD"));
        };

        const customList = (items, right) => (
            <Paper style={{minHeight: "545px"}} className={classes.paper}>
                <List dense component="div" role="list">
                    {items.map((item) => {
                        const labelId = `transfer-list-item-${item}-label`;

                        return (
                            <ListItem key={item} role="listitem" button onClick={handleToggle(item)}>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(item) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        style={{color: '#2460a7'}}
                                        inputProps={{'aria-labelledby': labelId}}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={handleUOsName(item)}/>
                            </ListItem>
                        );
                    })}
                    <ListItem/>
                </List>
                {!right && uos && uos.length > 0 && (
                    <Box display="flex" justifyContent="space-evenly" style={{padding: "5px"}}
                         className={classes.root}>
                        <Pagination
                            size={"small"}
                            showFirstButton
                            showLastButton
                            count={totalElementUOs % 10 === 0 ? totalElementUOs / 10 : Math.trunc(totalElementUOs / 10) + 1}
                            page={page + 1}
                            onChange={handleChangePage}
                        />
                    </Box>
                )}
            </Paper>
        );

        const init = () => {
            reset();
            setPeriod('');
            setValue('');
            setDisableBtn(false)
            setDateDeb(null);
            setDateFin(null);
            dispatch(initFields());
            // setChecked([]);
            // setLeftC([]);
            // setRightC([]);
        };


        const onSubmit = async values => {
            const matricules = value.split(',');
            let error = false;

            let isValid = value !== '' && dateFin !== null && dateDeb !== null && values.titre !== undefined &&
                values.modulationTypesDTO !== null;

            matricules.map(matricule => {
                if ((matricule.length !== 6 && matricule.length !== 10) || regex.test(matricule)) {
                    error = true;
                }
            });

            if (error || !isValid) {
                setAlert(true);
                setMessage(!isValid ? _isRequired : _verify);
                setTimeout(
                    () => {
                        setAlert(false);
                    }, 6000);
            } else {
                let newValues = value.replaceAll(',', '\',\'')
                newValues = '\'' + newValues + '\'';

                const body = {
                    ...values,
                    matriculesInclus: newValues,
                    modulationTypesDTO: values.modulationTypesDTO ? values.modulationTypesDTO : modulations[0],
                    dateDebutPaie: dateDeb,
                    dateFinPaie: dateFin,
                    periode: period
                }
                setDisableBtn(true)
                await dispatch(launch(body));
                setTimeout(() => {
                    setDisableBtn(false)
                    setDialog(true);
                }, 5000);
            }

        };

        const handleUOsName = id => {
            for (let uo in uos) {
                if (uos[uo].id === id) return uos[uo].shortName
            }
        };

        const handleChange = event => {
            const textareaLineHeight = 24;

            const previousRows = event.target.rows;
            event.target.rows = minRows; // reset number of rows in textarea

            const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

            if (currentRows === previousRows) {
                event.target.rows = currentRows;
            }

            if (currentRows >= maxRows) {
                event.target.rows = maxRows;
                event.target.scrollTop = event.target.scrollHeight;
            }

            setValue(event.target.value);
            setRows(currentRows < maxRows ? currentRows : maxRows)
        };

        const handleRules = async value => {
            if (value === 3) {
                setDisplay(true);
            } else {
                setDisplay(false);
            }
            dispatch(getModulations(value))
        }

        return (
            <ThemeContext.Consumer>
                {context => (
                    <div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
                        <Grid container>
                            <Grid item xs={6}>
                                <h1 style={{
                                    marginTop: 1,
                                    color: context.selectedTheme.color,
                                    fontWeight: 'normal'
                                }}>{_title}</h1>
                            </Grid>
                        </Grid>
                        <hr color="#f5f5f5"/>
                        <Collapse timeout={800} in={alert}>
                            <Alert onClose={() => setAlert(false)} severity="error">{message}</Alert>
                        </Collapse>
                        <br/>

                        <form style={{paddingTop: '20px', paddingBottom: '20px'}} onSubmit={handleSubmit(onSubmit)}>

                            {!display && (
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Field name="titre" label={_label} component={renderTextField}/>
                                        <br/><br/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl size={"medium"} variant={"outlined"} fullWidth>
                                            <InputLabel>
                                                {_rules}
                                            </InputLabel>
                                            <Field name="regulation" label={_rules} variant={'outlined'}
                                                   values={regulations} component={renderRulesComponent}
                                                   onChange={e => handleRules(e.target.value)}
                                            />
                                        </FormControl>
                                        <br/><br/>
                                    </Grid>
                                </Grid>
                            )}

                            {display && (
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Field name="titre" label={_label} component={renderTextField}/>
                                        <br/><br/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl size={"medium"} variant={"outlined"} fullWidth>
                                            <InputLabel>
                                                {_rules}
                                            </InputLabel>
                                            <Field name="regulation" label={_rules} variant={'outlined'}
                                                   values={regulations} component={renderRulesComponent}
                                                   onChange={e => handleRules(e.target.value)}/>
                                        </FormControl>
                                        <br/><br/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl size={"medium"} variant={"outlined"} fullWidth>
                                            <InputLabel>
                                                {_modulation}
                                            </InputLabel>
                                            <Field name="modulationTypesDTO" label={_modulation} variant={'outlined'}
                                                   values={modulations} component={renderSelectComponent}/>
                                        </FormControl>
                                        <br/><br/>
                                    </Grid>
                                </Grid>
                            )}
                            <br/>
                            <Grid container spacing={2}>
                                <Grid item style={{position: "relative", top: "-18.5px"}} xs={6}>
                                    <Field name="dateDeb" variant={'outlined'} component={renderDateComponent}
                                           handleDateChange={handleDateDebChange} maxDate={now()} disabled={false}
                                           selectedDate={dateDeb} label={_startDate}/>
                                </Grid>
                                <Grid item style={{position: "relative", top: "-18.5px"}} xs={6}>
                                    <Field name="dateFin" variant={'outlined'} component={renderDateComponent}
                                           handleDateChange={handleDateFinChange} maxDate={now()} minDate={moment(dateDeb)}
                                           disabled={dateDeb === null}
                                           selectedDate={dateFin} label={_endDate}
                                        //maxDate={moment(dateDeb).add(1, 'M')}
                                    />
                                </Grid>
                            </Grid>
                            <br/>
                            <Grid container spacing={2} className={classes.root}>
                                <div style={{
                                    width: '100%', padding: '8px',
                                }}>

                        <textarea
                            spellCheck={false}
                            rows={rows}
                            value={value}
                            maxLength={75000}
                            placeholder={_textArea}
                            className={classes.textarea}
                            onChange={handleChange}
                        />
                                </div>
                            </Grid>
                            <br/><br/>
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    size={"large"}
                                    variant="contained"
                                    style={pristine && value === '' && dateFin === null && dateDeb === null ? {
                                        color: '#fff',
                                        marginRight: 10,
                                        cursor: 'pointer',
                                        background: context.selectedTheme.color
                                    } : {
                                        color: '#fff',
                                        marginRight: 10,
                                        cursor: 'pointer',
                                        background: context.selectedTheme.color
                                    }}
                                    disabled={pristine && value === '' && dateFin === null && dateDeb === null}
                                    onClick={init} startIcon={<CancelIcon/>}>
                                    {_reset}
                                </Button>
                                <Button size={"large"} variant="contained"
                                        style={submitting || pristine ? {
                                            color: '#fff',
                                            width: 150,
                                            cursor: 'pointer',
                                            background: context.selectedTheme.color
                                        } : {
                                            color: '#fff',
                                            width: 150,
                                            cursor: 'pointer',
                                            background: context.selectedTheme.color
                                        }}
                                    disabled={ disableBtn || submitting || pristine}
                                        startIcon={<CheckCircleIcon/>} type="submit">
                                    {_validate}
                                </Button>

                            </Box>
                        </form>
                    </div>
                )}
            </ThemeContext.Consumer>
        );
    }
;

const mapStateToProps = state => {
    return {
        pays: state.evsReducer.pays,
        populations: state.evsReducer.populations,
        uos: state.evsReducer.uos,
        modulations: state.launcherReducer.modulations,
        regulations: state.launcherReducer.regulations,
        idUos: state.evsReducer.idUos,
        matricules: state.evsReducer.matricules,
        totalElementUOs: state.evsReducer.totalElementUOs,
        lang: state.salaryReducer.lang
    };
};
export default connect(mapStateToProps)(reduxForm({
    form: 'launcher',
    //validate,
    enableReinitialize: true
})(withStyles(styles)(Launcher)));
