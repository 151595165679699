import {GET_COUNTRY_TYPES_FAIL, GET_COUNTRY_TYPES_SUCCESS} from "../types/EvsTypes";

const initialState = {
    pays: [],
    countryTypes: [],
    evs: [],
    totalElements: 0,
    populations: [],
    codes: [],
    uos: [],
    matricules: [],
    idUos: [],
    totalElementUOs: 0,
    ids: [],
    page: 0,
    id: null,
    payss: '',
    population: '',
    code: '',
    description: '',
    valeur: '',
    loading: false,
    onDelete: false,
    onSave: false,
    filter: {
        codeVariable: null,
        idCountry: null,
        idPopulation: null
    }
};

export default function EVsReducer(state = initialState, action) {
    switch (action.type) {
        case "GET_DATA":
            return {
                ...state,
                id: action.result.id,
                payss: action.result.populationDTO && action.result.populationDTO.countryDTO.code,
                population: action.result.populationDTO.id,
                code: action.result.code,
                valeur: action.result.valeur,
                description: action.result.description
            };

        case "SET_CRITERIA":
            return {
                ...state,
                filter: action.values
            };

        case "INIT_DATA":
            return {
                ...state,
                id: null,
                payss: '',
                population: '',
                code: '',
                valeur: '',
                description: ''
            };

        case 'GET_ALL_POP':
            return {
                ...state,
                loading: true
            };
        case 'GET_ALL_POP_SUCCESS':
            return {
                ...state,
                loading: false,
                populations: action.result,
                ids: action.result.map(p => p.id)
            };
        case 'GET_ALL_POP_FAIL':
            return {
                ...state,
                loading: false,
                populations: []
            };
        case 'GET_ALL_UOs':
            return {
                ...state,
                loading: true
            };
        case 'GET_ALL_UOs_SUCCESS':
            return {
                ...state,
                loading: false,
                uos: action.result.content,
                totalElementUOs: action.result.totalElements,
                idUos: action.result.content.map(uo => uo.id),
            };
        case 'GET_ALL_UOs_FAIL':
            return {
                ...state,
                loading: false,
                idUos: [],
                totalElementUOs: 0,
                uos: []
            };

        case 'GET_ALL_COUNTRIES':
            return {
                ...state,
                loading: true
            };
        case 'GET_ALL_COUNTRIES_SUCCESS':
            return {
                ...state,
                loading: false,
                pays: action.result
            };
        case 'GET_ALL_COUNTRIES_FAIL':
            return {
                ...state,
                loading: false,
                pays: []
            };
        case GET_COUNTRY_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                countryTypes: action.result
            };
        case GET_COUNTRY_TYPES_FAIL:
            return {
                ...state,
                loading: false,
                countryTypes: []
            };

        case 'GET_ALL_EVS':
            return {
                ...state,
                loading: true
            };
        case 'GET_ALL_EVS_SUCCESS':
            return {
                ...state,
                loading: false,
                evs: action.result.content,
                totalElements: action.result.totalElements,
                codes: action.result.content.map(a => a.code)
            };
        case 'GET_ALL_EVS_FAIL':
            return {
                ...state,
                loading: false,
                evs: [],
                codes: [],
                totalElements: 0
            };

        case 'SET_PAGES':
            return {
                ...state,
                page: action.page
            };

        case 'REMOVE_EVS':
            return {
                ...state,
                onDelete: true
            };
        case 'REMOVE_EVS_SUCCESS':
            return {
                ...state,
                onDelete: false
            };
        case 'REMOVE_EVS_FAIL':
            return {
                ...state,
                onDelete: false
            };

        case 'SAVE_EVS':
            return {
                ...state,
                onSave: true
            };
        case 'SAVE_EVS_SUCCESS':
            return {
                ...state,
                onSave: false
            };
        case 'SAVE_EVS_FAIL':
            return {
                ...state,
                onSave: false
            };
        case 'GET_USERS_UOs_SUCCESS':
            return {
                ...state,
                matricules : action.result
                // matricules: ["044455","001222","M14455","M00021","444777","044455","001222","M14455","M00021","444777","044455","001222","M14455","M00021","444777","044455","001222","M14455","M00021", "444777"]
            };
        case 'GET_USERS_UOs_FAIL':
            return {
                ...state,
                matricules: []
            };

        default:
            return state;
    }
}
