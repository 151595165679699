import React, {useEffect, useState} from 'react';
import {Box, Button, FormControl, Grid, InputLabel, withStyles} from "@material-ui/core";
import {Field, reduxForm} from "redux-form";
import {renderDateComponent, renderSelectComponent} from "../launcher/Launcher";
import {getListHistory, getModulations, getRulesByCountry} from "../../actions/LauncherActions";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import TableComponent from "../../layout/TableComponent";
import {connect, useDispatch} from "react-redux";
import {styles} from "../../styles/CommonStyles";
import {ThemeContext} from "../../App";
import {strings} from "../../i18n/Strings";
import Loader from "../../layout/Loader";
import moment, {now} from "moment";

const History = ({
                     submitting, list, loading, reset, handleSubmit, periods, criteria,
                     pristine
                 }) => {

    const [dateDeb, setDateDeb] = useState(null);
    const [dateFin, setDateFin] = useState(null);
    const {_title, _search, _reset, _status, _period, _dateDeb, _dateFin} = strings.history;
    const {modulationHistory} = strings.columns;
    const dispatch = useDispatch();

    const columns = [
        {
            id: 1,
            code: 'uploadedBy',
            value: modulationHistory._launchBy,
        }, {
            id: 2,
            code: 'period',
            value: modulationHistory._period
        },
        {
            id: 3,
            code: 'dateDebutUpload',
            value: modulationHistory._startDate
        }, {
            id: 4,
            code: 'dateFinUpload',
            value: modulationHistory._endDate
        },
        {
            id: 5,
            code: 'statut',
            value: modulationHistory._status
        },
       ];

    useEffect(() => {
        dispatch(getListHistory(criteria))
    }, []);

    const onSubmit = () => {}

    const handleDateDebChange = (date) => {
        setDateDeb(moment(date).format("YYYY-MM-DD"));
    };

    const handleDateFinChange = (date) => {
        setDateFin(moment(date).format("YYYY-MM-DD"));
    };

    return (
        <ThemeContext.Consumer>
            {context => (
                <div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <h1 style={{
                                marginTop: 1,
                                color: context.selectedTheme.color,
                                fontWeight: 'normal'
                            }}>{_title}</h1>
                        </Grid>
                    </Grid>
                    <hr color="#f5f5f5"/>
                    <br/>
                    <form style={{paddingTop: '20px', paddingBottom: '20px'}} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl size={"medium"} variant={"outlined"} fullWidth>
                                    <InputLabel>
                                        {_period}
                                    </InputLabel>
                                    <Field name="period" label={_period} variant={'outlined'}
                                           values={periods} component={renderSelectComponent}/>
                                </FormControl>
                                <br/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl size={"medium"} variant={"outlined"} fullWidth>
                                    <InputLabel>
                                        {_status}
                                    </InputLabel>
                                    <Field name="status" label={_status} variant={'outlined'}
                                           values={[]} component={renderSelectComponent}/>
                                </FormControl>
                                <br/>
                            </Grid>
                        </Grid>
                        <br/>
                        <Grid container spacing={2}>
                            <Grid item style={{position: "relative", top: "-18.5px"}} xs={6}>
                                <Field name="dateDeb" variant={'outlined'} component={renderDateComponent}
                                       handleDateChange={handleDateDebChange} maxDate={now()} disabled={false}
                                       selectedDate={dateDeb} label={_dateDeb}/>
                            </Grid>
                            <Grid item style={{position: "relative", top: "-18.5px"}} xs={6}>
                                <Field name="dateFin" variant={'outlined'} component={renderDateComponent}
                                       handleDateChange={handleDateFinChange} maxDate={now()} minDate={moment(dateDeb)}
                                       disabled={dateDeb === null}
                                       selectedDate={dateFin} label={_dateFin}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                size={"large"}
                                variant="contained"
                                style={{
                                    color: '#fff',
                                    marginRight: 10,
                                    cursor: 'pointer',
                                    background: context.selectedTheme.color
                                }}
                                //disabled={pristine && value === '' && dateFin === null && dateDeb === null}
                                onClick={reset}
                                startIcon={<CancelIcon/>}>
                                {_reset}
                            </Button>
                            <Button size={"large"} variant="contained"
                                    style={{
                                        color: '#fff',
                                        width: 150,
                                        cursor: 'pointer',
                                        background: context.selectedTheme.color
                                    }}
                                    disabled={submitting || pristine}
                                    startIcon={<CheckCircleIcon/>} type="submit">
                                {_search}
                            </Button>
                        </Box>
                    </form>
                    <TableComponent color={context.selectedTheme.color} periods={periods} list={list}
                                    columns={columns}/>

                    <Loader color={context.selectedTheme.color} open={loading}/>
                </div>

            )}
        </ThemeContext.Consumer>
    );
};

const mapStateToProps = state => {
    return {
        periods: state.PayrollEngineReducer.periods,
        list: state.PayrollEngineReducer.listHistory,
        criteria: state.PayrollEngineReducer.criteria,
        years: state.PayrollEngineReducer.years,
        loading: state.PayrollEngineReducer.loading,
        sheets: state.PayrollEngineReducer.sheets,
        lang: state.salaryReducer.lang
    };
};

export default connect(mapStateToProps)(reduxForm({
    form: 'history'
})(withStyles(styles)(History)));
