const apiMapping = {
    "prod": "",
    "preprod": "",
    "staging": "",
    "dev": "",
    "localDev": "http://localhost:2020",

};
export const API = apiMapping[process.env.REACT_APP_ENV];

export const CORE_API = "https://mscore.intelcia.com";
export const DEAFULT_AVATAR_CORE_SERVER = "picture/default";
export const OAUTH_LOGOUT_DEV = "http://oauth.intelcia.com/uaa/logout";
export const OAUTH_LOGOUT_PROD = "https://oauth.intelcia.com/uaa/logout";
