import {makeStyles} from "@material-ui/core/styles";
import {ThemeContext} from "../../App";

export const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'Inter, sans-serif',
        flexGrow: 1,
    },
    table: {
        minWidth: 650,
    },
    tableContainer: {
        border:'1px solid rgba(203, 213, 225, 1)',
        borderRadius: '6px',
        overflow: 'hidden',
    },
    paper: {
        marginRight: theme.spacing(1),
        boxShadow: 'none',
    },
    formContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
    },
    form: {
        display: 'flex',
        alignItems: 'center',
    },
    field: (props) => ({
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px',
        marginRight: theme.spacing(1),
        borderRadius: '8px',
        outline: 'none',
        width: '330px',
        height: '50px',
        '&.Mui-focused fieldset': {
            borderColor: `${props.color} !important`  // Border color when focused
        },
    }),
    select: (props) => ({
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px',
        marginRight: theme.spacing(1),
        borderRadius: '8px',
        outline: 'none',
        width: '220px',
        height: '50px',
        '&.Mui-focused fieldset': {
            borderColor: `${props.color} !important`,  // Border color when focused with important
        },
    }),
    title: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '17px',
        fontWeight: 500,
        textAlign: 'left',
    },
    header: {
        fontFamily: 'Inter, sans-serif',
        backgroundColor: 'rgba(234, 236, 240, 1)',
        color: 'rgba(102, 112, 133, 1)',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '18px',
        textAlign: 'left',
    },
    tableRow: {
        '&:nth-of-type(even)': {
            backgroundColor: 'rgba(247, 247, 252, 1)',
        },
    },
    cell: {
        fontFamily: 'Inter, sans-serif',
        color: 'rgba(52, 64, 84, 1)',
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '18px',
        textAlign: 'left',
        border: "none",
        padding: '15px 18px'
    },
    activeStatus: {
        padding: '5px',
        borderRadius: '8px',
        color: 'rgba(2, 122, 72, 1)',
        backgroundColor: 'rgba(209, 250, 223, 1)'
    },
    deletedStatus: {
        padding: '5px',
        borderRadius: '8px',
        color: 'rgba(102, 112, 133, 1)',
        backgroundColor: 'rgba(234, 236, 240, 1)'
    },
    ul: {
        '& .MuiPaginationItem-page': {
            border:'1px solid rgba(234, 236, 240, 1)',
            fontFamily: 'Inter, sans-serif',
            fontSize: '14px',
            fontWeight: 500,
            borderRadius: '4px',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            color: 'rgba(102, 112, 133, 1)',
        },
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: props => props.color,
            color: '#fff',
        },
        '& .MuiPaginationItem-page.Mui-selected:hover': {
            backgroundColor: props => props.color,
            opacity: 0.8
        },
    },
    button: (props) => ({
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px',
        marginRight: theme.spacing(1),
        borderRadius: '8px',
        outline: 'none',
        width: '200px',
        background: 'rgb(41, 135, 18) !important',
        height: '50px',
        boxShadow: "none",
        color: "white !important",
        textTransform: 'none',
    }),
    deleted: {
        display: 'inline-block',
        alignItems: 'center',
        padding: '5px 8px',
        gap: '8px',
        background: '#D1FADF',
        borderRadius: '8px',
        flex: 'none',
        order: '0',
        flexGrow: '0'
    },
    notDeleted: {
        display: 'inline-block',
        alignItems: 'center',
        padding: '5px 8px',
        gap: '8px',
        background: '#EAECF0',
        borderRadius: '8px',
        flex: 'none',
        order: '0',
        flexGrow: '0'
    },
}));
