import {applyMiddleware, combineReducers, createStore,} from "redux";
import {reducer as reduxFormReducer} from 'redux-form';
import logger from "redux-logger";
import thunk from "redux-thunk";
import authReducer from "../reducers/AuthReducer";
import i18nReducer from "../reducers/i18nReducer";
import salaryReducer from "../reducers/salaryReducer";
import EVsReducer from "../reducers/EVsReducer";
import excludeReducer from "../reducers/excludeReducer";
import CounterReducer from "../reducers/CounterReducer";
import launcherReducer from "../reducers/LauncherReducer";
import PayrollEngineReducer from "../reducers/PayrollEngineReducer";
import exportReducer from "../reducers/exportReducer";
import settingReducer from "../reducers/SettingsReducer";

export const store = createStore(combineReducers({
        form: reduxFormReducer,
        salaryReducer: salaryReducer,
        evsReducer: EVsReducer,
        counterReducer: CounterReducer,
        exclusReducer: excludeReducer,
        launcherReducer: launcherReducer,
        PayrollEngineReducer: PayrollEngineReducer,
        exportReducer: exportReducer,
        settingReducer:settingReducer,
        auth: authReducer,
        i18n: i18nReducer
    }, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()),
    applyMiddleware(thunk, logger));
