import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Tooltip,
    withStyles,
} from "@material-ui/core";
import {connect, useDispatch} from "react-redux";
import {Field, reduxForm} from "redux-form";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CachedIcon from '@material-ui/icons/Cached';
import {Pagination} from "@material-ui/lab";
import DeleteComponent from "./DeleteComponent";
import {CustomTableCell} from "../evs/EVsComponent";
import {styles} from "../../styles/CommonStyles";
import {
    getLaunchHistory, getModulationsTypes, InitDetails, retryLaunchMotor, setFilter
} from "../../actions/LauncherActions";
import CircularProgressWithLabel from "../../utilities/CircularProgressWithLabel";
import {renderTextField} from "../../layout/renderComponents";
import Loader from "../../layout/Loader";
import CancelIcon from '@material-ui/icons/Cancel';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import RejectedIcon from '../../assets/images/1213.png';
import ValidatedIcon from '../../assets/images/1212.png';
import CreatedIcon from '../../assets/images/8989.png';
import {ThemeContext} from "../../App";
// import VisibilityIcon from '@material-ui/icons/Visibility';
import {makeStyles} from "@material-ui/core/styles";
import VisibilityIcon from '@material-ui/icons/Assignment';
import {strings} from "../../i18n/Strings";
import {store} from "../../store/ConfigureStore";

const TextOnlyTooltip = withStyles({
    tooltip: {
        color: "white", fontFamily: 'Roboto', backgroundColor: "#4b82bb"
    }
})(Tooltip);

export const renderSelectField = ({input, label, values, meta: {touched, error}, ...custom}) => (<Select
        label={label}
        {...input}
        {...custom}
    >
        <MenuItem value={""}>
            <em>-- SÉLÉCTIONNER --</em>
        </MenuItem>
        {values && values.map(val => <MenuItem key={val.id} value={val.code}>{val.value.toUpperCase()}</MenuItem>)}
    </Select>);

export const renderModulationSelectField = ({input, label, values, meta: {touched, error}, ...custom}) => (<Select
        label={label}
        {...input}
        {...custom}
    >
        <MenuItem value={""}>
            <em>-- SÉLÉCTIONNER --</em>
        </MenuItem>
        {values && values.map(val => <MenuItem key={val.id} value={val.id}>{val.libelle}</MenuItem>)}
    </Select>);

export const getMonth = (month, periods) => {
    const period = periods.find(period => period.code === month);
    if (period !== undefined) {
        return period.value.toUpperCase();
    }

    return undefined;

}


const useStyles = makeStyles({
    ul: {
        "& .MuiPaginationItem-root": {
            background: props => props.context && props.context.color, color: '#fff'
        }
    },
});

const PayrollEngineComponent = (props) => {

    const [open, setOpen] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);
    const [page, setPage] = useState(0);
    const [data, setData] = useState({});
    const {
        loading,
        classes,
        history,
        totalElements,
        list,
        handleSubmit,
        reset,
        pristine,
        submitting,
        filter,
        states,
        periods,
        status,
        modulationTypes
    } = props;

    const {ul} = useStyles(props);

    const {
        _engine,
        _title,
        _state,
        _status,
        _period,
        _noDataFound,
        _init,
        _search,
        _visualise,
        _delete,
        _deleted,
        _retry,
        _pending,
        _validate,
        _valid,
        _failed,
        _reject
    } = strings;

    const {payroll} = strings.columns;

    const columns = [{headerName: payroll._title}, {headerName: payroll._modulation}, {headerName: payroll._period}, {headerName: payroll._startDate}, {headerName: payroll._endDate}, {headerName: payroll._launchBy}, {headerName: payroll._status}, {headerName: payroll._percentage}, {headerName: payroll._actions}];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLaunchHistory(page, true, filter));
        dispatch(getModulationsTypes());
        dispatch(InitDetails());
    }, []);

    useEffect(() => {
        const updateData = () => {
            const payload = store.getState().PayrollEngineReducer.filter;
            dispatch(getLaunchHistory(page, false, payload));
        };
        const interval = setInterval(updateData, 10000);

        return () => clearInterval(interval);

    }, [page]);

    const onClose = () => {
        setOpen(false);
    };
    const onCloseDetail = () => {
        setOpenDetail(false);
    };

    const handleChangePage = async (event, newPage) => {
        await dispatch(getLaunchHistory(newPage - 1, true, filter));
        setPage(newPage - 1);
    };

    const onSubmit = async values => {
        await dispatch(getLaunchHistory(0, true, values));
        await dispatch(setFilter(values));
    };

    const onInit = async () => {
        reset();
        await dispatch(getLaunchHistory(0, true, {}));
        await dispatch(setFilter({}));

    };

    return (<ThemeContext.Consumer>
            {context => (<div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
                    <h1 style={{
                        marginTop: 1, color: context.selectedTheme.color, fontWeight: 'normal'
                    }}>{_engine}</h1>
                    <hr color="#f5f5f5"/>
                    <br/>
                    <form style={{padding: 20, border: 'solid 2px #fafafa'}} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Field name="titre" component={renderTextField} label={_title}/>
                                <br/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl size={"small"} variant={"outlined"} fullWidth>
                                    <InputLabel>
                                        {_status}
                                    </InputLabel>
                                    <Field name="statut" label={_status} values={status}
                                           component={renderSelectField}/>
                                </FormControl>
                                <br/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl size={"small"} variant={"outlined"} fullWidth>
                                    <InputLabel>
                                        Modulation
                                    </InputLabel>
                                    <Field name="idModulation" label="Modulation" values={modulationTypes}
                                           component={renderModulationSelectField}/>
                                </FormControl>
                                <br/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl size={"small"} variant={"outlined"} fullWidth>
                                    <InputLabel>
                                        {_period}
                                    </InputLabel>
                                    <Field name="periode" label={_period} values={periods}
                                           component={renderSelectField}/>
                                </FormControl>
                                <br/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl size={"small"} variant={"outlined"} fullWidth>
                                    <InputLabel>
                                        {_state}
                                    </InputLabel>
                                    <Field name="etat" label={_state} values={states}
                                           component={renderSelectField}/>
                                </FormControl>
                                <br/><br/>
                            </Grid>

                        </Grid>

                        <Box display="flex" justifyContent="flex-end">
                            <Button style={pristine ? {
                                marginRight: '15px', color: '#fff', background: context.selectedTheme.color
                            } : {
                                marginRight: '15px', color: '#fff', background: context.selectedTheme.color
                            }} size={"medium"} variant="contained"
                                    disabled={pristine} onClick={onInit}
                                    startIcon={<CancelIcon/>}>{_init}
                            </Button>
                            <Button size={"medium"}
                                    variant="contained" disabled={pristine || submitting}
                                    type="submit"
                                    style={pristine || submitting ? {
                                        color: '#fff', background: context.selectedTheme.color
                                    } : {
                                        color: '#fff', background: context.selectedTheme.color
                                    }}
                                    startIcon={<SearchRoundedIcon/>}
                            >{_search}
                            </Button>
                        </Box>
                    </form>
                    <br/>
                    <Table className={classes.table}>
                        <TableHead style={{background: context.selectedTheme.color}}>
                            <TableRow>
                                {columns && columns.map(column => <CustomTableCell align="center"><span style={{
                                    fontSize: '13px', fontWeight: 'bold'
                                }}>{column.headerName}</span></CustomTableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list && list.map(row => (<TableRow className={classes.row} key={row.id}>
                                    <CustomTableCell align="center">{row.titre.toUpperCase()}</CustomTableCell>
                                    <CustomTableCell
                                        align="center">{row.modulationTypesDTO && row.modulationTypesDTO.libelle.toUpperCase()}</CustomTableCell>
                                    {/*<CustomTableCell align="center">-</CustomTableCell>*/}
                                    {/*<CustomTableCell align="center">-</CustomTableCell>*/}
                                    <CustomTableCell align="center">{getMonth(row.periode, periods)}</CustomTableCell>
                                    <CustomTableCell
                                        align="center">{row.dateLancement && row.dateLancement.replace('T', ' ')}</CustomTableCell>
                                    <CustomTableCell
                                        align="center">{row.dateFinLancement ? row.dateFinLancement.replace('T', ' ') : '--'}</CustomTableCell>
                                    {/*<CustomTableCell align="center">{row.valide ?*/}
                                    {/*    <img src={Checked} width="20px" height="20px" alt="checked"/>*/}
                                    {/*    :*/}
                                    {/*    <img src={Unchecked} width="19px" height="19px" alt="unchecked"/>}</CustomTableCell>*/}
                                    <CustomTableCell align="center">{row.lancerPar.toUpperCase()}</CustomTableCell>
                                    <CustomTableCell align="center">
                                        <Tooltip style={{cursor: 'pointer'}}
                                                 title={row.statut === 'CREATED' ? _validate : row.statut === 'VALIDATED' ? _valid : row.statut === 'DELETED' ? _deleted : row.statut === 'REJECTED' ? _reject : _failed}>
                                            {row.statut === 'CREATED' ? (<img src={CreatedIcon} width={24} height={24}
                                                                              alt="created"/>) : row.statut === 'VALIDATED' ? (
                                                <img width={24} height={24} src={ValidatedIcon} alt="validated"/>) : (
                                                <img width={29} height={29} src={RejectedIcon} alt="rejected"/>)}
                                        </Tooltip>

                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        <CircularProgressWithLabel color={context.selectedTheme.color}
                                                                   value={row.pourcentage}
                                                                   state={row.etatLancemet}/>
                                    </CustomTableCell>
                                    <CustomTableCell align="center">
                                        {row.statut === 'ECHOUE' ? (<>
                                                <a style={{cursor: 'pointer', position: 'relative', bottom: 5}}
                                                   onClick={() => {
                                                       dispatch(retryLaunchMotor(row))
                                                   }}>
                                                    <Tooltip title={_retry} aria-label="add">
                                                        <CachedIcon
                                                            style={{
                                                                color: context.selectedTheme.color,
                                                                position: 'relative',
                                                                top: 7,
                                                                transform: 'scale(1.16)'
                                                            }}/>
                                                    </Tooltip>
                                                </a>

                                                <a style={{cursor: 'pointer', position: 'relative', bottom: 5}}
                                                   onClick={() => {
                                                       setData(row);
                                                       setOpen(true);
                                                   }}>
                                                    <Tooltip title={_delete} aria-label="add">
                                                        <DeleteForeverIcon
                                                            style={{
                                                                color: context.selectedTheme.color,
                                                                position: 'relative',
                                                                top: 7,
                                                                transform: 'scale(1.17)'
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </a>

                                            </>

                                        ) : (<>
                                                <a style={{cursor: 'pointer', position: 'relative', bottom: 5}}
                                                   onClick={() => {
                                                       if (row.etatLancemet === 'E') history.push("/paie_" + row.id)
                                                   }}
                                                >
                                                    <Tooltip
                                                        title={row.etatLancemet === 'E' ? _visualise + ' (' + row.id + ')' : _pending + ' (' + row.id + ')'}
                                                        aria-label="see">
                                                        <VisibilityIcon
                                                            style={{
                                                                opacity: row.etatLancemet !== 'E' ? '50%' : '100%',
                                                                position: "relative",
                                                                top: 7,
                                                                color: context.selectedTheme.color
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </a>
                                                {row.statut !== 'DELETED' && (
                                                    <a style={{cursor: 'pointer', position: 'relative', bottom: 5}}
                                                       onClick={() => {
                                                           setData(row);
                                                           setOpen(true);
                                                       }}>
                                                        <Tooltip title={_delete} aria-label="add">
                                                            <DeleteForeverIcon
                                                                style={{
                                                                    color: context.selectedTheme.color,
                                                                    position: 'relative',
                                                                    top: 6,
                                                                    transform: 'scale(1.17)'
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </a>)}
                                            </>)}


                                    </CustomTableCell>
                                </TableRow>))}
                        </TableBody>
                    </Table>

                    {list && list.length === 0 && (<div className={classes.root}>
                            <p style={{
                                paddingBottom: '0px',
                                color: context.selectedTheme.color,
                                textAlign: 'center',
                                fontSize: '25px',
                                fontFamily: 'Varela Round'
                            }}>
                                {_noDataFound}
                            </p>
                        </div>)}

                    {list && list.length > 0 && (<Grid container style={{position: "relative", top: 20}}>
                            <Grid item xs={6}>
                                <Box display="flex">
                                    <Pagination
                                        size={"small"}
                                        showFirstButton
                                        classes={{
                                            ul: ul
                                        }}
                                        showLastButton
                                        count={totalElements % 10 === 0 ? totalElements / 10 : Math.trunc(totalElements / 10) + 1}
                                        page={page + 1}
                                        onChange={handleChangePage}
                                    />
                                </Box>
                            </Grid>
                        </Grid>)}

                    <DeleteComponent color={context.selectedTheme.color} setPage={setPage} classes={classes} data={data}
                                     open={open} onClose={onClose} filter={filter}/>

                    <Loader color={context.selectedTheme.color} open={loading}/>

                </div>)}
        </ThemeContext.Consumer>);
}

const mapStateToProps = state => {
    return {
        loading: state.PayrollEngineReducer.loading,
        context: state.auth.context,
        list: state.PayrollEngineReducer.list,
        modulationTypes: state.launcherReducer.modulationTypes,
        filter: state.PayrollEngineReducer.filter,
        periods: state.PayrollEngineReducer.periods,
        status: state.PayrollEngineReducer.status,
        states: state.PayrollEngineReducer.states,
        totalElements: state.PayrollEngineReducer.totalElements,
        lang: state.salaryReducer.lang
    };
};
export default connect(mapStateToProps)(reduxForm({
    form: 'launch', enableReinitialize: true
})(withStyles(styles)(PayrollEngineComponent)));
