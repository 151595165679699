import { API } from "../api/Endpoints";
import { getAxios } from "./AuthActions";
import {
    GET_REF_MODULATION_USER,
    GET_REF_MODULATION_USER_SUCCESS,
    GET_REF_MODULATION_USER_FAIL,
    GET_REF_WORKING_HOURS,
    GET_REF_WORKING_HOURS_SUCCESS,
    GET_REF_WORKING_HOURS_FAIL,
    GET_MODULATION,
    GET_MODULATION_SUCCESS,
    GET_MODULATION_FAIL,
    EXPORT_REF_MODULATION_USER_FAIL,
    EXPORT_REF_MODULATION_USER_SUCCESS,
    EXPORT_REF_MODULATION_USER,
    EXPORT_REF_WORKING_HOURS_USER,
    EXPORT_REF_WORKING_HOURS_SUCCESS,
    EXPORT_REF_WORKING_HOURS_FAIL,
    EXPORT_MODULATION,
    EXPORT_MODULATION_SUCCESS,
    EXPORT_MODULATION_FAIL,
    GET_EXCLUDED_USERS,
    GET_EXCLUDED_USERS_SUCCESS,
    GET_EXCLUDED_USERS_FAIL,
    GET_EXCLUDED_SITES_SUCCESS,
    GET_EXCLUDED_SITES,
    GET_EXCLUDED_SITES_FAIL,
    EXPORT_EXCLUDED_USERS,
    EXPORT_EXCLUDED_USERS_SUCCESS,
    EXPORT_EXCLUDED_USERS_FAIL,
    EXPORT_EXCLUDED_SITES, EXPORT_EXCLUDED_SITES_SUCCESS, EXPORT_EXCLUDED_SITES_FAIL
} from "../types/SettingTypes";
import moment from "moment/moment";
import XLSX from 'xlsx';

export const getExcludedUsers = (registrationNumber, page) => async dispatch => {
    const axios = getAxios();
    dispatch({ type: GET_EXCLUDED_USERS });
    axios({
        url: `${API}/api/excludedUsers/findAll?registrationNumber=${registrationNumber}&page=${page}&size=10`,
        method: 'get'
    })
        .then(response => {
            dispatch({
                type: GET_EXCLUDED_USERS_SUCCESS,
                result: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: GET_EXCLUDED_USERS_FAIL,
                error: error.message
            });
        });
};

export const exportExcludedUsers = (registrationNumber) => async dispatch => {
    const axios = getAxios();
    dispatch({ type: EXPORT_EXCLUDED_USERS });
    axios({
        url: `${API}/api/excludedUsers/find-all-list?registrationNumber=${registrationNumber}`,
        method: 'get'
    })
        .then(response => {
            dispatch({
                type: EXPORT_EXCLUDED_USERS_SUCCESS,
                result: response.data
            });
            const worksheet = XLSX.utils.json_to_sheet(response.data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `excluded_users_${moment(new Date()).format("YYYY-MM-DD")}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            dispatch({
                type: EXPORT_EXCLUDED_USERS_FAIL,
                error: error.message
            });
        });
};

export const getExcludedSites = (codeSirh, page) => async dispatch => {
    const axios = getAxios();
    dispatch({ type: GET_EXCLUDED_SITES });
    axios({
        url: `${API}/api/excludedSites/findAll?codeSirh=${codeSirh}&page=${page}&size=10`,
        method: 'get'
    })
        .then(response => {
            dispatch({
                type: GET_EXCLUDED_SITES_SUCCESS,
                result: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: GET_EXCLUDED_SITES_FAIL,
                error: error.message
            });
        });
};

export const exportExcludedSites = (codeSirh) => async dispatch => {
    const axios = getAxios();
    dispatch({ type: EXPORT_EXCLUDED_SITES });
    axios({
        url: `${API}/api/excludedSites/find-all-list?codeSirh=${codeSirh}`,
        method: 'get'
    })
        .then(response => {
            dispatch({
                type: EXPORT_EXCLUDED_SITES_SUCCESS,
                result: response.data
            });
            const worksheet = XLSX.utils.json_to_sheet(response.data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `excluded_sites_${moment(new Date()).format("YYYY-MM-DD")}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            dispatch({
                type: EXPORT_EXCLUDED_SITES_FAIL,
                error: error.message
            });
        });
};

export const exportRefModulationUser = (filter) => async dispatch => {
    const axios = getAxios();
    dispatch({ type: EXPORT_REF_MODULATION_USER });
    axios({
        url: `${API}/api/mu/find-all-list?db=MYSHIFT2`,
        method: 'post',
        data: filter
    })
        .then(response => {
            dispatch({
                type: EXPORT_REF_MODULATION_USER_SUCCESS,
                result: response.data
            });
            const worksheet = XLSX.utils.json_to_sheet(response.data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ref_modulation_user_${moment(new Date()).format("YYYY-MM-DD")}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            dispatch({
                type: EXPORT_REF_MODULATION_USER_FAIL,
                error: error.message
            });
        });
};

export const getRefModulationUser = (filter, page) => async dispatch => {
    const axios = getAxios();
    dispatch({ type: GET_REF_MODULATION_USER });
    axios({
        url: `${API}/api/mu/find-all?db=MYSHIFT2&page=${page}&size=10`,
        method: 'post',
        data: filter
    })
        .then(response => {
            dispatch({
                type: GET_REF_MODULATION_USER_SUCCESS,
                result: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: GET_REF_MODULATION_USER_FAIL,
                error: error.message
            });
        });
};

export const exportRefWorkingHours = (filter) => async dispatch => {
    const axios = getAxios();
    dispatch({ type: EXPORT_REF_WORKING_HOURS_USER });
    axios({
        url: `${API}/api/ht/find-all-list?db=MYSHIFT2`,
        method: 'post',
        data: filter
    })
        .then(response => {
            dispatch({
                type: EXPORT_REF_WORKING_HOURS_SUCCESS,
                result: response.data
            });
            const worksheet = XLSX.utils.json_to_sheet(response.data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ref_heures_travaillees_${moment(new Date()).format("YYYY-MM-DD")}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            dispatch({
                type: EXPORT_REF_WORKING_HOURS_FAIL,
                error: error.message
            });
        });
};

export const getRefWorkingHours = (filter, page) => async dispatch => {
    const axios = getAxios();
    dispatch({ type: GET_REF_WORKING_HOURS });
    axios({
        url: `${API}/api/ht/find-all?db=MYSHIFT2&page=${page}&size=10`,
        method: 'post',
        data: filter
    })
        .then(response => {
            dispatch({
                type: GET_REF_WORKING_HOURS_SUCCESS,
                result: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: GET_REF_WORKING_HOURS_FAIL,
                error: error.message
            });
        });
};

export const exportModulation = (filter) => async dispatch => {
    const axios = getAxios();
    dispatch({ type: EXPORT_MODULATION });
    axios({
        url: `${API}/api/modulation/find-all-list?db=MYSHIFT2`,
        method: 'post',
        data: filter
    })
        .then(response => {
            dispatch({
                type: EXPORT_MODULATION_SUCCESS,
                result: response.data
            });
            const worksheet = XLSX.utils.json_to_sheet(response.data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `modulation_${moment(new Date()).format("YYYY-MM-DD")}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            dispatch({
                type: EXPORT_MODULATION_FAIL,
                error: error.message
            });
        });
};

export const getModulation = (filter, page) => async dispatch => {
    const axios = getAxios();
    dispatch({ type: GET_MODULATION });
    axios({
        url: `${API}/api/modulation/find-all?db=MYSHIFT2&page=${page}&size=10`,
        method: 'post',
        data: filter
    })
        .then(response => {
            dispatch({
                type: GET_MODULATION_SUCCESS,
                result: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: GET_MODULATION_FAIL,
                error: error.message
            });
        });
};
