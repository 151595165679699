import {getAxios, getCurrentUser} from "./AuthActions";
import {API} from "../api/Endpoints";
import {
    GET_ALL_USERS,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_FAIL,
    GET_ALL_SITES,
    GET_ALL_SITES_SUCCESS,
    GET_ALL_SITES_FAIL,
    SET_INPUT_VAL,
    SET_USERS_PAGE,
    SET_SITES_PAGE,
    ADD_SITE_SUCCESS, ADD_SITE, ADD_SITE_FAIL
} from "../types/ExludeTypes";
import Swal from "sweetalert2";
import {alertx} from "../utilities/Alert";

export const getAllUsers = (username, page) => async dispatch => {
    const axios = getAxios();
    dispatch({
        type: GET_ALL_USERS
    });
    axios({
        url: `${API}/api/excludedUsers/findAllActive?registrationNumber=${username}&page=${page}&size=10`,
        method: 'get',
    })
        .then(response => {
            dispatch({
                type: GET_ALL_USERS_SUCCESS,
                result: response.data
            });
        })
        .catch(() => {
            dispatch({
                type: GET_ALL_USERS_FAIL,
                result: []
            });
        })
};
export const getAllSites = (codeSirh, page) => async dispatch => {
    const axios = getAxios();
    dispatch({
        type: GET_ALL_SITES,
    });
    axios({
        url: `${API}/api/excludedSites/findAllActive?codeSirh=${codeSirh}&page=${page}&size=10`,
        method: 'get',
    })
        .then(response => {
            dispatch({
                type: GET_ALL_SITES_SUCCESS,
                result: response.data
            });
        })
        .catch(() => {
            dispatch({
                type: GET_ALL_SITES_FAIL,
                result: []
            });
        })
};

export const removeExcluded = (type, id) => async dispatch => {
    const axios = getAxios();
    const {userName} = getCurrentUser();
    axios({
        url: type === 'users' ? `${API}/api/excludedUsers/delete?id=${id}&user=${userName}` :
            `${API}/api/excludedSites/delete?id=${id}&user=${userName}`,
        method: 'delete',
    })
        .then(() => {
            alertx('success',  type === 'users' ? 'L\'AGENT A BIEN ÉTÉ SUPPRIMÉ' : 'LE SITE A BIEN ÉTÉ SUPPRIMÉ');
        })
        .catch(() => {
            alertx('error',  'UN PROBLÈME TECHNIQUE EST SURVENU');
        })
};
export const addExcludedSite = codeSirh => async dispatch => {
    dispatch({
        type: ADD_SITE
    });
    const axios = getAxios();
    const {userName} = getCurrentUser();
    axios({
        url: `${API}/api/excludedSites/add?codeSirh=${codeSirh}`,
        method: 'post',
    })
        .then(res => {
            if (res.status === 200) {
                const flag1 = res.data && res.data.message === 'Aucun site trouve';
                const flag2 = res.data && res.data.message === 'Excluded site déjà existe';
                if (flag1 || flag2) {
                    dispatch({
                        type: ADD_SITE_FAIL
                    });
                    alertx('error', flag1 ? 'AUCUN SITE TROUVÉ' : 'SITE DÉJÀ EXCLUS');
                } else {
                    dispatch({
                        type: ADD_SITE_SUCCESS
                    });
                    dispatch(getAllSites("", 0));
                }

            } else {
                alertx('error', 'OPÉRATION ÉCHOUÉE');
            }

        })
        .catch(() => {
            dispatch({
                type: ADD_SITE_FAIL
            });
            alertx('error', 'un problème technique est survenu');
        })
};
export const addExcludedUsers = matricule => async dispatch => {
    dispatch({
        type: ADD_SITE
    });
    const axios = getAxios();
    // const {userName} = getCurrentUser();
    axios({
        url: `${API}/api/excludedUsers/add?matricules=${matricule}`,
        method: 'post',
    })
        .then(res => {
            dispatch({
                type: ADD_SITE_SUCCESS
            });
            const flag = res.data && res.data.message && res.data.message.includes('deja exclus');
            if (res.status === 200 && flag ) {
                alertx('error', 'AGENCE DÉJÀ EXCLUS');
            }else {
                dispatch(getAllUsers("", 0));
            }

        })
        .catch(() => {
            dispatch({
                type: ADD_SITE_FAIL
            });
            alertx('error', 'un problème technique est survenu');
        })
};
export const setInputVal = value => async dispatch => {
    dispatch({
        type: SET_INPUT_VAL,
        inputVal: value
    })
};
export const setPageSites = page => async dispatch => {
    dispatch({
        type: SET_SITES_PAGE,
        page
    })
};
export const setPageUsers = page => async dispatch => {
    dispatch({
        type: SET_USERS_PAGE,
        page
    })
};
