import React, {useContext, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {strings} from "../../i18n/Strings";
import {exportRefModulationUser, getRefModulationUser} from "../../actions/SettingsActions";
import {FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {Pagination} from "@material-ui/lab";
import {useStyles} from "./style";
import {ThemeContext} from "../../App";
import exportIcon from "../../assets/images/export.png";
import Loader from "../../layout/Loader";


const RefModulationComponent = ({ loading, data, totalPages, fetchData, isExporting, exportDataExcel }) => {
    const [page, setPage] = useState(0);
    const context = useContext(ThemeContext);
    const classes = useStyles(context.selectedTheme);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [matricule, setMatricule] = useState('');
    const [status, setStatus] = useState('');
    const [date, setDate] = useState(null);
    const {_refModulationUser,_deleted,_active,_export} = strings.setting;
    const {
        _status,
        _creationDate,
        _endDate,
        _startDate,
        _matricule,
    } = strings.setting.columns;
    const columns = [
        { headerName: _matricule, dataKey: 'matricule' },
        { headerName: _status, dataKey: 'status' },
        { headerName: _creationDate, dataKey: 'created' },
        { headerName: _endDate, dataKey: 'end' },
        { headerName: "NPAC", dataKey: 'npac' },
        { headerName: "NPMC", dataKey: 'npmc' },
        { headerName: "PMC", dataKey: 'pmc' },
        { headerName: "PPAC", dataKey: 'ppac' },
        { headerName: "PPMC", dataKey: 'ppmc' },
        { headerName: _startDate, dataKey: 'start' },
    ];

    useEffect(() => {
        fetchData({matricule:matricule,status:status}, 0);
    }, [status,matricule]);

    const handleChangePage = async (event, newPage) => {
        setPage(newPage - 1);
        await fetchData({matricule:matricule,status:status}, newPage - 1);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDateChange = (date) => {
        console.log(date)
        setDate(date);
    };

    const exportData = () => {
        exportDataExcel({matricule:matricule,status:status});
    }

    return (
        <ThemeContext.Consumer>
            {context => (
                <div className={classes.root}>
                    <div className={classes.formContainer}>
                        <Typography variant="h6" className={classes.title}
                                    style={{
                                        color: context.selectedTheme.color}}
                        >
                            {_refModulationUser}
                        </Typography>
                        <div className={classes.form}>
                            <TextField id="outlined-basic"
                                       InputProps={{
                                           className: classes.field,
                                           endAdornment: (
                                               <InputAdornment position="end">
                                                   <SearchIcon />
                                               </InputAdornment>
                                           )
                                       }}
                                       value={matricule}
                                       label={_matricule} variant="outlined"
                                       onChange={(e) => setMatricule(e.target.value)}
                            />
                            <FormControl InputProps={{
                                className:classes.select}} variant="outlined">
                                <InputLabel id="demo-simple-select-autowidth-label">{_status}</InputLabel>
                                <Select
                                    className={classes.select}
                                    labelId="demo-simple-select-autowidth-label"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    label={_status}
                                >
                                    <MenuItem value={"DELETED"}>{_deleted}</MenuItem>
                                    <MenuItem value={"ACTIVE"}>{_active}</MenuItem>
                                </Select>
                            </FormControl>
                            <Button variant="contained" disabled={isExporting} className={classes.button} onClick={exportData}>
                                <img style={{position: 'relative', right: '1vh'}} src={exportIcon} width={20} alt="ss"/>
                                {_export}  .xlsx
                            </Button>
                        </div>
                    </div>

                    <Paper className={classes.paper}>
                        <TableContainer className={classes.tableContainer}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow >
                                        {columns.map((column, index) => (
                                            <TableCell className={classes.header} key={index}>{column.headerName}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row, rowIndex) => (
                                        <TableRow key={rowIndex} className={classes.tableRow}
                                        >
                                            {columns.map((column, colIndex) => (
                                                <TableCell
                                                    key={colIndex}
                                                    className={classes.cell}
                                                >
                                            <span
                                                className={`${column.dataKey === 'status' && row[column.dataKey] === 'ACTIVE' ? classes.activeStatus : ''} ${column.dataKey === 'status' && row[column.dataKey] === 'DELETED' ? classes.deletedStatus : ''}`}
                                            >
                                                {row[column.dataKey]}
                                            </span>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{marginTop: "10px",display:"flex",justifyContent:"end"}}>
                            <Pagination
                                count={totalPages}
                                page={page + 1}
                                onChange={handleChangePage}
                                color="primary"
                                shape="rounded"
                                variant="outlined"
                                className={
                                    classes.ul
                                }
                            />
                        </div>
                        <Loader color={context.selectedTheme.color} open={loading}/>
                    </Paper>
                </div>
            )}
        </ThemeContext.Consumer>
    );
};

const mapStateToProps = (state) => ({
    loading: state.settingReducer.loading,
    data: state.settingReducer.refModulationData,
    isExporting: state.settingReducer.isExporting,
    totalPages: state.settingReducer.refModulationPages,
    lang: state.salaryReducer.lang
});

const mapDispatchToProps = {
    fetchData: getRefModulationUser,
    exportDataExcel: exportRefModulationUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(RefModulationComponent);
