
export const environmentPreprod = {
    production: false,
    cross: {
        withCredentials: true
    },
    oauth : {
        token: 'https://preprod-oauth.intelcia.com/uaa/oauth/token',
        authorize: 'https://preprod-oauth.intelcia.com/uaa/oauth/authorize',
        state: 'INTELCIA_OAUTH_SESSION_STATE',
        code: 'INTELCIA_OAUTH_SESSION_CODE',
        remoteUser: 'INTELCIA_OAUTH_REMOTE_USER',
        scope: 'openid',
        secret: '45c4aV35Z1Eg52c',
        client_id: 'salary',
        response_type: 'code',

        redirectTo: new URL(window.location.href).origin,
    }
};

export const environmentStaging = {
    production: false,
    cross: {
        withCredentials: true
    },
    oauth : {
        token: 'https://test-oauth.intelcia.com/uaa/oauth/token',
        authorize: 'https://test-oauth.intelcia.com/uaa/oauth/authorize',
        state: 'INTELCIA_OAUTH_SESSION_STATE',
        code: 'INTELCIA_OAUTH_SESSION_CODE',
        remoteUser: 'INTELCIA_OAUTH_REMOTE_USER',
        scope: 'openid',
        secret: '45c4aV35Z1Eg52c',
        client_id: 'salary',
        response_type: 'code',

        redirectTo: new URL(window.location.href).origin,
    }
};

export const environmentDev = {
    production: false,
    cross: {
        withCredentials: true
    },
    oauth : {
        token: 'https://dev-oauth.intelcia.com/uaa/oauth/token',
        authorize: 'https://dev-oauth.intelcia.com/uaa/oauth/authorize',
        state: 'INTELCIA_OAUTH_SESSION_STATE',
        code: 'INTELCIA_OAUTH_SESSION_CODE',
        remoteUser: 'INTELCIA_OAUTH_REMOTE_USER',
        scope: 'openid',
        secret: '45c4aV35Z1Eg52c',
        client_id: 'salary',
        response_type: 'code',

        redirectTo: new URL(window.location.href).origin,
    }
};




export const environmentProd = {
    production: true,
    cross: {
        withCredentials: true
    },
    oauth : {
        token: 'https://oauth.intelcia.com/uaa/oauth/token',
        authorize: 'https://oauth.intelcia.com/uaa/oauth/authorize',
        state: 'INTELCIA_OAUTH_SESSION_STATE',
        code: 'INTELCIA_OAUTH_SESSION_CODE',
        remoteUser: 'INTELCIA_OAUTH_REMOTE_USER',
        scope: 'openid',
        secret: '45c4aV35Z1Eg52c',
        client_id: 'salary',
        response_type: 'code',
        redirectTo: new URL(window.location.href).origin,
    }
};


export const environmentLocalDev = {
    production: false,
    cross: {
        withCredentials: true
    },
    oauth : {
        token: 'https://dev-oauth.intelcia.com/uaa/oauth/token',
        authorize: 'https://dev-oauth.intelcia.com/uaa/oauth/authorize',
        state: 'INTELCIA_OAUTH_SESSION_STATE',
        code: 'INTELCIA_OAUTH_SESSION_CODE',
        remoteUser: 'INTELCIA_OAUTH_REMOTE_USER',
        scope: 'openid',
        secret: '45c4aV35Z1Eg52c',
        client_id: 'salary',
        response_type: 'code',
        redirectTo: new URL(window.location.href).origin
    }
};

