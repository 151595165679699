export const GET_ROWS = 'GET_ROWS';
export const GET_ROWS_SUCCESS = 'GET_ROWS_SUCCESS';
export const GET_ROWS_FAIL = 'GET_ROWS_FAIL';

export const GET_LAUNCH_DETAIL = 'GET_LAUNCH_DETAIL';
export const GET_LAUNCH_DETAIL_SUCCESS = 'GET_LAUNCH_DETAIL_SUCCESS';
export const GET_LAUNCH_DETAIL_FAIL = 'GET_LAUNCH_DETAIL_FAIL';
export const SET_FILTER = 'SET_FILTER';

export const EXPORT = 'EXPORT';
export const EXPORT_SUCCESS = 'EXPORT_SUCCESS';
export const EXPORT_FAIL = 'EXPORT_FAIL';

export const VALIDATE = 'VALIDATE';
export const VALIDATE_SUCCESS = 'VALIDATE_SUCCESS';
export const VALIDATE_FAIL = 'VALIDATE_FAIL';

export const GET_LAUNCH_BY_ID = 'GET_LAUNCH_BY_ID';
export const GET_LAUNCH_BY_ID_SUCCESS = 'GET_LAUNCH_BY_ID_SUCCESS';
export const GET_LAUNCH_BY_ID_FAIL = 'GET_LAUNCH_BY_ID_FAIL';

export const GET_SHEETS = 'GET_SHEETS';
export const GET_SHEETS_SUCCESS = 'GET_SHEETS_SUCCESS';
export const GET_SHEETS_FAIL = 'GET_SHEETS_FAIL';

export const GET_HISTORY = 'GET_HISTORY';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const GET_HISTORY_FAIL = 'GET_HISTORY_FAIL';

export const IMPORT = 'IMPORT';
export const IMPORT_SUCCESS = 'IMPORT_SUCCESS';
export const IMPORT_FAIL = 'IMPORT_FAIL';
export const GET_ALL_SERVERS_SUCCESS = 'GET_ALL_SERVERS_SUCCESS';
export const GET_ALL_SERVERS = 'GET_ALL_SERVERS';
export const GET_ALL_SERVERS_FAIL = 'GET_ALL_SERVERS_FAIL';
export const ADD_REGULATION = 'ADD_REGULATION';
export const ADD_REGULATION_SUCCESS = 'ADD_REGULATION_SUCCESS';
export const ADD_REGULATION_FAIL = 'ADD_REGULATION_FAIL';
export const GET_MODULATION_TYPE = 'GET_MODULATION_TYPE';
export const GET_MODULATION_TYPE_SUCCESS = 'GET_MODULATION_TYPE_SUCCESS';
export const GET_MODULATION_TYPE_FAIL = 'GET_MODULATION_TYPE_FAIL';


