import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import {connect} from "react-redux";
import {strings} from "../../i18n/Strings";
import RefModulationComponent from "./RefModulationComponent";
import RefWorkingHoursComponent from "./RefWorkingHoursComponent";
import ModulationComponent from "./ModulationComponent";
import {ThemeContext} from "../../App";
import ExcludedCasesViewerComponent from "./ExcludedCasesViewerComponent";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        margin: '20px',
    },
    title: {
        textTransform: 'uppercase',
        fontFamily: "Inter,sans-serif",
        fontSize: '17px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0.04em',
        textAlign: 'left',
        marginBottom: '16px',
        marginTop: '25px',
        color: 'rgba(24, 138, 175, 1)'
    },
    tabs: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: 'rgba(102, 112, 133, 1)',
        textTransform: 'lowercase'
    },
    tab: {
        fontWeight: 600,
        fontSize: '14px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        textTransform: 'capitalize'
},
});


const SettingsViewerComponent = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const {_modulation,_refModulationUser,_refWorkingHours,_excludedCases,_settingTitle} = strings.setting;
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <ThemeContext.Consumer>
            {context => (
                <div className={classes.root}>
                    <Typography className={classes.title} style={{
                        color: context.selectedTheme.color}}>{_settingTitle}</Typography>
                    <AppBar className={classes.tabs} position="static">
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                            <Tab
                                label={_refModulationUser}
                                value={0}
                                className={classes.tab}
                                style={{
                                    color: value === 0 ? context.selectedTheme.color : 'rgba(102, 112, 133, 1)'
                                }}
                            />
                            <Tab
                                label={_refWorkingHours}
                                value={1}
                                className={classes.tab}
                                style={{
                                    color: value === 1 ? context.selectedTheme.color : 'rgba(102, 112, 133, 1)'
                                }}
                            />
                            <Tab
                                label={_modulation}
                                value={2}
                                className={classes.tab}
                                style={{
                                    color: value === 2 ? context.selectedTheme.color : 'rgba(102, 112, 133, 1)'
                                }}
                            />
                            <Tab
                                label={_excludedCases}
                                value={3}
                                className={classes.tab}
                                style={{
                                    color: value === 3 ? context.selectedTheme.color : 'rgba(102, 112, 133, 1)'
                                }}
                            />
                        </Tabs>
                    </AppBar>
                    {value === 0 && <RefModulationComponent/>}
                    {value === 1 && <RefWorkingHoursComponent/>}
                    {value === 2 && <ModulationComponent/>}
                    {value === 3 && <ExcludedCasesViewerComponent/>}
                </div>
            )}
        </ThemeContext.Consumer>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.counterReducer.loading,
        lang: state.salaryReducer.lang
    };
};
export default connect(mapStateToProps)(SettingsViewerComponent);
