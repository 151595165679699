import {API} from "../api/Endpoints";
import {alertx} from "../utilities/Alert";
import {getAxios} from "./AuthActions";
import {ADD_REGULATION, ADD_REGULATION_FAIL, ADD_REGULATION_SUCCESS} from "../types/PayrollEngineTypes";

export const addRegulation = payload => dispatch => {
    const axios = getAxios();

    dispatch({
        type: ADD_REGULATION
    });

    axios({
        url: `${API}/api/modulationTypes/persistModulation`,
        method: 'post',
        data: payload
    }).then(() => {
        dispatch({
            type: ADD_REGULATION_SUCCESS
        });
        alertx('success', 'OPÉRATION RÉUSSITE');
    }).catch(() => {
        dispatch({
            type: ADD_REGULATION_FAIL
        });
        alertx('error',  'UN PROBLÈME TECHNIQUE EST SURVENU');

    });
}




