export const language =
    window.navigator.userLanguage || window.navigator.language;

export function hasLanguage() {
    return language === "fr-FR" ? "DD/MM/YYYY" : "YYYY/MM/DD";
}

export const stringsLangs = {
    fr: {
        title: {
            _launch: 'LANCEMENT DU CALCUL',
            _periodicLaunch: 'LANCEMENT PÉRIODIQUE',
            _global: 'GLOBAL HEURES',
            _modulation: 'MODULATION',
            _exclude: 'CAS EXCLUS',
            _payEngine: 'MOTEUR DE PAIE',
            _salary: 'BULLETINS DE PAIE',
            _settings: 'PARAMÉTRAGES',
            _IR: 'DÉCLARATION IRS',
            _TRANSLATE: 'Traduire',
            _themeChange: 'Changer le Thème',
            _logOut: 'Déconnexion',
            _USERS: 'UTILISATEURS',
            _SITES: 'SITES',
            REGULATIONS: 'RÉGLEMENTATIONS',
            SERVERS: 'SERVEURS',
        },
        setting: {
            _active: "Active",
            _deleted: "Supprimé",
            _settingTitle: 'Paramétrage',
            _refWorkingHours: 'Ref heures travaillées',
            _refModulationUser: 'Ref modulation user',
            _modulation: 'Modulation',
            _excludedCases: 'Les cas exclus',
            _excludedUsers: 'Collaborateurs',
            _excludedSites: 'Sites',
            _export: 'Exporter',
            columns: {
                _matricule: 'Matricule',
                _startDate: 'Date de début',
                _endDate: 'Date de fin',
                _creationDate: 'Date de création',
                _updatedDate: 'Date de modification',
                _month: 'Mois',
                _year: 'Année',
                _counter: 'Counter',
                _user: 'Utilisateur',
                _status: 'Statut',
                _dateEffet: 'Date d\'effet',
                _rule: 'Réglementation',
            }
        },
        columns: {
            payroll: {
                _title: 'TITRE',
                _modulation: 'MODULATION',
                _period: 'PÉRIODE',
                _startDate: 'DATE DÉBUT LANCEMENT',
                _endDate: 'DATE FIN LANCEMENT',
                _launchBy: 'LANCÉ PAR',
                _status: 'STATUT',
                _percentage: 'POURCENTAGE',
                _actions: 'ACTIONS'
            },
            modulationHistory: {
                _launchBy: 'LANCÉ PAR',
                _period: 'PÉRIODE',
                _startDate: 'DATE DÉBUT OPÉRATION',
                _endDate: 'DATE FIN OPÉRATION',
                _status: 'STATUT',
                _sheets: 'FEUILLE'
            },
            excluderUser: {
                _matricule: 'Matricule',
                _firstname: 'Prénom',
                _lastname: 'Nom',
                _email: 'Email',
                _creationDate: 'Date opération',
                _entryDate: 'Date entrée',
                _actions: 'Action'
            },
            excluderSite: {
                _label: 'Libellé',
                _site: 'Site',
                _siteId: 'Site ID',
                _code: 'Code',
                _sirh: 'Code SIRH',
                _creationDate: 'Date opération',
                _actions: 'Action'
            }
        },
        payrollDetail: {
            _title: 'DÉTAIL RÉSULTAT DE CALCUL',
            _global: 'GLOBAL HOURES',
            _validBtn: 'VALIDER',
            _rejectBtn: 'REJTER',
            _exportJ: 'EXPORT JOURNALIER',
            _exportM: 'EXPORT MENSUEL',
            _exportT: 'EXPORT TRANSPORT',
            _payExport: 'EXPORT PAIE',
            _excelExport: 'EXPORT EXCEL',
            _excelExportT: 'EXPORT TRANSPORT EXCEL',
            _noDataFound: 'AUCUN RÉSULTAT TROUVÉ',
            _attention: 'ATTENTION',
            _isValidated: 'VOULEZ-VOUS VALIDER CE RÉSULTAT DE CALCUL ?',
            _isRejected: 'VOULEZ-VOUS REJETER CE RÉSULTAT DE CALCUL ?',
            _cancel: 'ANNULER',
            _reset: 'Réinitialiser',
            _search: 'RECHERCHER',
            _period: 'PÉRIODE',
            _year: 'ANNÉE',
        },
        launch: {
            _title: 'LANCEMENT DU CALCUL',
            _label: 'TITRE',
            _modulation: 'MODULATION',
            _country: 'PAYS',
            _rules: 'RÉGLEMENTATION',
            _startDate: 'DATE PAIE DÉBUT',
            _endDate: 'DATE PAIE FIN',
            _periodicity: 'PÉRIODICITÉ',
            _validate: 'VALIDER',
            _reset: 'Réinitialiser',
            _textArea: 'ENTRER LES MATRICULES ...',
            _isRequired: 'Tous les champs sont obligatoires',
            _verify: 'Merci de vérifier les matricules saisies !',
        },
        history: {
            _title: 'HISTORIQUE MODULATION',
            _select: 'SÉLECTIONNER',
            _status: 'STATUT',
            _dateDeb: 'DATE DÉBUT',
            _dateFin: 'DATE FIN',
            _period: 'PÉRIODE',
            _reset: 'RÉINITIALISER',
            _search: 'RECHERCHER',
        },
        modulation: {
            _title: 'IMPORT MODULATION',
            _history: 'HISTORIQUE',
            _reset: 'Réinitialiser',
            _import: 'IMPORTER',
            _msg: 'Le fichier est rejecté. Seuls les fichiers EXCEL sont pris en charge',
            _file: 'Déposez un fichier ici ou cliquez',
            _year: 'ANNÉE',
            _period: 'PÉRIODE',
            _pending: 'OPÉRATION EN COURS DE TRAITEMENT',
            _select_file: 'Sélectionner un fichier',
            _date: 'Date',
            _type: 'Type'
        },
        userExclusion: {
            _title: 'PARAMÉTRAGE DES UTILISATEURS EXCLUS',
            _agentSearch: 'Rechercher un agent...',
            _add: 'AJOUTER',
            _list: 'LISTE',
            _delete: 'SUPPRIMER',
            _search: 'RECHERCHER'
        },
        siteExclusion: {
            _title: 'PARAMÉTRAGE DES SITES EXCLUS',
            _search: 'RECHERCHER',
            _add: 'AJOUTER',
            _list: 'LISTE',
            _delete: 'SUPPRIMER',
            _siteSearch: 'RECHERCHER UN SITE...'
        },
        REGULATIONS: {
            LIST_REGULATION: {
                _TITLE: 'LISTE RÉGLEMENTATIONS',
            },
            NEW_REGULATION: {
                _TITLE: 'NOUVELLE RÉGLEMENTATION',
                _INIT_BUTTON: 'RÉINITIALISER',
                _ADD_BUTTON: 'AJOUTER',
                FIELDS: {
                    _COUNTRY_TYPE: 'TYPE DE PAYS',
                    _DESCRIPTION: 'DESCRIPTION RÉGLEMENTATION',
                    _NAME: 'NOM RÉGLEMENTATION',
                    _CODE: 'CODE MODULATION',
                    _LABEL: 'LIBELLÉ MODULATION',
                    _COUNTRIES: 'PAYS',
                }
            },
        },
        SERVERS: {
            _TITLE: 'LISTE MOTEURS DE PAIE',
            COLUMNS: {
                _NAME: 'LIBELLÉ',
                _IP: 'IP',
                _EXCEPTION: 'EXCEPTION',
                _STATUS: 'STATUT',
            },
            _ENABLED: 'DÉMARRÉ',
            _DISABLED: 'ARRÊTÉ',
        },
        _isDeleted: 'VOULEZ-VOUS SUPPRIMER LE LANCEMENT ',
        _deleteUser: 'VOULEZ-VOUS SUPPRIMER LE COLLABORATEUR ',
        _deleteSite: 'VOULEZ-VOUS SUPPRIMER LE SITE ',
        _choixCompteur: 'COMPTEURS PAR POPULATION',
        _choixVariable: 'VARIABLES PAR POPULATION',
        _updateTitle: 'MODIFIER EVs ',
        _detailsTitle: 'DÉTAILS EVs ',
        _addTitle: 'AJOUTER UN EVs',
        _paramCompteur: 'COMPTEUR',
        _engine: 'MOTEUR DES ÉLÉMENTS VARIABLES',
        _status: 'Statut',
        _period: 'Période',
        _state: 'État de lancement',
        _title: 'Titre',
        _init: 'Réinitialiser',
        _search: 'Rechercher',
        _visualise: 'VISUALISER',
        _pending: 'EN COURS DE PRÉPARATION',
        _delete: 'SUPPRIMER',
        _deleted: 'Supprimé',
        _oui: 'Oui',
        _non: 'Non',
        _retry: 'RELANCER LE CALCUL',
        _noDataFound: 'AUCUN RÉSULTAT TROUVÉ',
        _validate: 'EN ATTENTE DE VALIDATION',
        _valid: 'VALIDÉ',
        _reject: 'REJETÉ',
        _failed: 'ÉCHOUÉ',
        _myshiftModifs: 'MYSHIFT',
        _salaryTitle: 'MES BULLETINS DE PAIE',
        _irsTitle: 'DÉCLARATION IRS',
        _downloadBtn: 'TÉLÉCHARGER',
        _refreshBtn: 'RAFRAICHIR',
        _backBtn: 'RETOUR',
        _securedPDF: 'Pdf sécurisé',
        _allStatements: 'TOUS LES BULLETINS ',
        _allIrs: 'TOUS LES DÉCLARATION IRS ',
        _statement: 'BULLETINS ',
        _irs: 'IR ',
        _warning: 'Vos bulletins de paie ne seront pas protégés par un code d\'accès personnel',
        _warningIR: 'Vos déclarations IRS ne seront pas protégées par un code d\'accès personnel',
        _continueBtn: 'CONTINUER',
        _cancelBtn: 'ANNULER',
        _okBtn: 'CONFIRMER',
        _JAN: 'JAN',
        _FEV: 'FÉV',
        _MAR: 'MAR',
        _AVR: 'AVR',
        _MAI: 'MAI',
        _JUN: 'JUN',
        _JUL: 'JUL',
        _AOU: 'AOÛ',
        _SEP: 'SEP',
        _OCT: 'OCT',
        _NOV: 'NOV',
        _DEC: 'DÉC',
        lang: "fr",
        _notFound: 'Aucun bulletin de paie trouvé',
        _notFoundIR: 'Aucune déclarations IRS  trouvée',
        _attention: 'ATTENTION',
        _code: 'CODE DE SÉCURITÉ',
        _msg: 'Merci de renseigner votre code personnel pour sécuriser votre bulletin de paie',
        _msgIR: 'Merci de renseigner votre code personnel pour sécuriser votre déclaration IRS',
        _desc: '<p>Votre code d\'accès personnel vous permet de protéger l\'accès à vos bulletins de paie, notamment quand ' +
            'ceux-ci sont téléchargés sur des ordinateurs dont l\'utilisation est possible par d\'autres collaborateurs.' +
            '<br/> Nous vous recommandons fortement de garder votre code personnel et de protéger ainsi l\'accès' +
            ' à vos bulletins de paie</p>',
        _descIR: '<p>Votre code d\'accès personnel vous permet de protéger l\'accès à vos déclarations IRS, notamment quand ' +
            'ceux-ci sont téléchargés sur des ordinateurs dont l\'utilisation est possible par d\'autres collaborateurs.' +
            '<br/> Nous vous recommandons fortement de garder votre code personnel et de protéger ainsi l\'accès' +
            ' à vos déclarations IRS</p>',
        _submitBtn: 'VALIDER',
        _addCounter: 'AJOUTER UN COMPTEUR',
        _updateCounter: 'MODIFIER LE COMPTEUR'
    },
    en: {
        title: {
            _settings: 'SETTINGS',
            _launch: 'START CALCULATION',
            _periodicLaunch: 'PERIODIC LAUNCH',
            _global: 'TOTAL HOURS',
            _modulation: 'MODULATION',
            _exclude: 'EXCLUDED CASES',
            _salary: 'PAY SLIPS',
            _payEngine: 'PAYROLL ENGINE',
            _IR: 'TAX DECLARATION',
            _TRANSLATE: 'Translate',
            _themeChange: 'Change Theme',
            _logOut: 'Log out',
            _USERS: 'USERS',
            _SITES: 'SITES',
            REGULATIONS: 'REGULATIONS',
            SERVERS: 'SERVERS',
        },
        setting: {
            _active: "Active",
            _deleted: "Deleted",
            _settingTitle: 'Setting',
            _refWorkingHours: 'Ref Working Hours',
            _refModulationUser: 'Ref Modulation User',
            _modulation: 'Modulation',
            _excludedCases: 'Excluded cases',
            _excludedUsers: 'Employees',
            _excludedSites: 'Sites',
            _export: 'Export',
            columns: {
                _matricule: 'Employee ID Number',
                _startDate: 'Start Date',
                _endDate: 'End Date',
                _creationDate: 'Creation Date',
                _updatedDate: 'Modification Date',
                _month: 'Month',
                _year: 'Year',
                _counter: 'Counter',
                _user: 'User',
                _status: 'Status',
                _dateEffet: 'Effective Date',
                _rule: 'Regulation',
            }
        },
        columns: {
            payroll: {
                _title: 'TITLE',
                _modulation: 'MODULATION',
                _period: 'PERIOD',
                _startDate: 'LAUNCH START DATE',
                _endDate: 'LAUNCH END DATE',
                _launchBy: 'LAUNCHED BY',
                _status: 'STATUS',
                _percentage: 'PERCENTAGE',
                _actions: 'ACTIONS',
            },
            modulationHistory: {
                _launchBy: 'LAUNCHED BY',
                _period: 'PERIOD',
                _startDate: 'START DATE OF OPERATION',
                _endDate: 'END DATE OF OPERATION',
                _status: 'STATUS',
                _sheets: 'SHEET',
            },
            excluderUser: {
                _matricule: 'EMPLOYEE ID NUMBER',
                _firstname: 'FIRST NAME',
                _lastname: 'LAST NAME',
                _email: 'EMAIL',
                _creationDate: 'OPERATION DATE',
                _entryDate: 'DATE ENTERED',
                _actions: 'ACTION'
            },
            excluderSite: {
                _label: 'TITLE',
                _site: 'Site',
                _siteId: 'Site ID',
                _code: 'CODE',
                _sirh: 'HRIS CODE',
                _creationDate: 'OPERATION DATE',
                _actions: 'ACTION'
            }
        },
        payrollDetail: {
            _title: 'CALCULATION RESULT DETAILS',
            _validBtn: 'APPROVE',
            _rejectBtn: 'REJECT',
            _exportJ: 'DAILY EXPORT',
            _exportM: 'MONTHLY EXPORT',
            _payExport: 'PAYROLL EXPORT',
            _excelExport: 'EXCEL EXPORT',
            _noDataFound: 'NO RESULTS FOUND',
            _attention: 'CAUTION',
            _isValidated: 'DO YOU WANT TO APPROVE THIS CALCULATION RESULT?',
            _isRejected: 'DO YOU WANT TO REJECT THIS CALCULATION RESULT?',
            _cancel: 'CANCEL',
            _reset: 'Reset',
            _search: 'SEARCH',
            _period: 'PERIOD',
            _year: 'YEAR',
            _global: 'TOTAL HOURS'
        },
        launch: {
            _title: 'START CALCULATION',
            _label: 'TITLE',
            _modulation: 'MODULATION',
            _country: 'COUNTRY',
            _rules: 'REGULATION',
            _startDate: 'PAYROLL START DATE',
            _endDate: 'PAYROLL END DATE',
            _periodicity: 'PERIODICITY',
            _validate: 'APPROVE',
            _reset: 'Reset',
            _textArea: 'ENTER THE EMPLOYEE ID NUMBERS ...',
            _isRequired: 'All fields are mandatory',
            _verify: 'Please check the employee numbers you have entered!',
        },
        history: {
            _title: 'MODULATION HISTORY',
            _reset: 'RESET',
            _search: 'SEARCH',
            _status: 'STATUS',
            _dateDeb: 'START DATE',
            _select: 'SELECT',
            _dateFin: 'END DATE',
            _period: 'PERIOD',
        },
        modulation: {
            _title: 'IMPORT MODULATION',
            _history: 'HISTORY',
            _reset: 'Reset',
            _import: 'IMPORT',
            _msg: 'The file has been rejected. Only EXCEL files are supported',
            _file: 'Drop a file here or click',
            _year: 'YEAR',
            _period: 'PERIOD',
            _pending: 'OPERATION IN PROGRESS',
            _select_file: 'Select file',
            _date: 'Date',
            _type: 'Type'
        },
        userExclusion: {
            _title: 'CONFIGURATION OF EXCLUDED USERS',
            _agentSearch: 'Search for an agent...',
            _add: 'ADD',
            _list: 'LIST',
            _delete: 'DELETE',
            _search: 'SEARCH',
        },
        siteExclusion: {
            _title: 'CONFIGURATION OF EXCLUDED SITES',
            _siteSearch: 'SEARCH A SITE...',
            _add: 'ADD',
            _list: 'LIST',
            _delete: 'DELETE',
            _search: 'SEARCH',
        },
        REGULATIONS: {
            LIST_REGULATION: {
                _TITLE: 'REGULATIONS LIST',
            },
            NEW_REGULATION: {
                _TITLE: 'NEW REGULATION',
                _INIT_BUTTON: 'RESET',
                _ADD_BUTTON: 'ADD',
                FIELDS: {
                    _COUNTRY_TYPE: 'COUNTRY TYPE',
                    _DESCRIPTION: 'REGULATION DESCRIPTION',
                    _NAME: 'REGULATION NAME',
                    _CODE: 'MODULATION CODE',
                    _LABEL: 'MODULATION LABEL ',
                    _COUNTRIES: 'COUNTRIES',
                }
            },
        },
        SERVERS: {
            _TITLE: 'SERVERS LIST',
            COLUMNS: {
                _NAME: 'LABEL',
                _IP: 'IP',
                _EXCEPTION: 'EXCEPTION',
                _STATUS: 'STATUS',
            },
            _ENABLED: 'RUNNING',
            _DISABLED: 'DOWN',
        },
        _isDeleted: 'DO YOU WANT TO DELETE THE LAUNCH ',
        _deleteUser: 'DO YOU WANT TO DELETE THE EMPLOYEE ',
        _deleteSite: 'DO YOU WANT TO DELETE THE SITE ',
        _engine: 'VARIABLE ELEMENT ENGINE',
        _status: 'Status',
        _period: 'Period',
        _state: 'Launch status',
        _title: 'Title',
        _init: 'Reset',
        _search: 'Search',
        _visualise: 'VIEW',
        _pending: 'IN PREPARATION',
        _delete: 'DELETE',
        _deleted: 'DELETED',
        _oui: 'Yes',
        _non: 'No',
        _retry: 'RESTART THE CALCULATION',
        _noDataFound: 'NO RESULTS FOUND',
        _validate: 'PENDING APPROVAL',
        _valid: 'APPROVED',
        _reject: 'REJECTED',
        _failed: 'FAILED',
        _choixCompteur: 'METERS BY POPULATION',
        _choixVariable: 'VARIABLES BY POPULATION',
        _updateTitle: 'MODIFY VEs ',
        _detailsTitle: 'VE DETAILS ',
        _addTitle: 'ADD VEs',
        _paramCompteur: 'METER',
        _myshiftModifs: 'MYSHIFT',
        _desc: '<p>Your personal access code allows you to protect access your pay slips, especially when they are ' +
            'downloaded to computers that can be used by other employees.' +
            '<br/><br/> We strongly recommend that you keep your personal code to protect access your pay slips.</p>',
        _descIR: '<p>Your personal access code allows you to protect access to your tax declarations, especially when they are ' +
            'downloaded to computers that can be used by other employees.' +
            '<br/><br/> We strongly recommend that you keep your personal code to protect access to your tax declarations.</p>',
        _salaryTitle: 'MY PAY SLIPS',
        _irsTitle: 'IRS RETURN',
        _downloadBtn: 'DOWNLOAD',
        _refreshBtn: 'REFRESH',
        _backBtn: 'BACK',
        _securedPDF: 'Secured PDF',
        _allStatements: 'ALL PAY SLIPS ',
        _allIrs: 'All tax declarations ',
        _statement: 'PAY SLIPS ',
        _irs: 'TAX ',
        _warning: 'Your pay slips will not be protected by a personal access code',
        _warningIR: 'Your tax declarations will not be protected by a personal access code',
        _continueBtn: 'CONTINUE',
        _cancelBtn: 'CANCEL',
        _okBtn: 'CONFIRM',
        _JAN: 'JAN',
        _FEV: 'FEB',
        _MAR: 'MAR',
        _AVR: 'APRIL',
        _MAI: 'MAY',
        _JUN: 'JUNE',
        _JUL: 'JULY',
        _AOU: 'AUG',
        _SEP: 'SEPT',
        _OCT: 'OCT',
        _NOV: 'NOV',
        _DEC: 'DEC',
        lang: "en",
        _attention: 'ATTENTION',
        _code: 'SECURITY CODE',
        _msg: 'Please enter your personal code to secure your pay slip',
        _msgIR: 'Please enter your personal code to secure your tax declaration',
        _submitBtn: 'APPROVE',
        _notFound: 'No pay slips found',
        _notFoundIR: 'No tax declaration found',
        _addCounter: 'ADD A METER',
        _updateCounter: 'MODIFY THE METER',
        _ramadanTitle: 'Adan Calendar',
    },
    pt: {
        title: {
            _settings: 'CONFIGURAÇÕES',
            _launch: 'LANÇAMENTO DO CÁLCULO',
            _periodicLaunch: 'LANCAMENTO PERIÓDICO',
            _global: 'HORAS GLOBAIS',
            _modulation: 'MODULAÇÃO',
            _exclude: 'CASOS EXCLUÍDOS',
            _salary: 'RECIBO DE VENCIMENTO',
            _payEngine: 'MOTOR DE VENCIMENTO',
            _IR: 'DECLARAÇÃO IRS',
            _TRANSLATE: 'Traduzir',
            _themeChange: 'mudança de tema',
            _logOut: 'desconexão',
            _USERS: 'USUÁRIOS',
            _SITES: 'SITIOS',
            REGULATIONS: 'REGULAMENTOS',
            SERVERS: 'SERVIDORES',
        },
        setting: {
            _active: "ACTIVE",
            _deleted: "REMOVIDO",
            _settingTitle: 'Contexto',
            _refWorkingHours: 'REF HORAS TRABALHADAS',
            _refModulationUser: 'REF MODULAÇÃO USER',
            _modulation: 'MODULAÇÃO',
            _excludedCases: 'CASOS EXCLUÍDOS',
            _excludedUsers: 'FUNCIONÁRIOS',
            _excludedSites: 'SITIOS',
            _export: 'Exportar',
            columns: {
                _matricule: 'MATRÍCULA',
                _startDate: 'DATA INÍCIO DE LANÇAMENTO',
                _endDate: 'DATA FIM LANÇAMENTO',
                _creationDate: 'DATA DE criação',
                _updatedDate: 'DATA DE MODIFICAÇÃO',
                _month: 'MÊS',
                _year: 'ANO',
                _counter: 'COUNTER',
                _user: 'USUÁRIO',
                _status: 'STATUS',
                _dateEffet: 'DATA DE EFEITO',
                _rule: 'REGULAMENTO',
            }
        },
        columns: {
            payroll: {
                _title: 'TÍTULO',
                _modulation: 'MODULAÇÃO',
                _period: 'PERÍODO',
                _startDate: 'DATA INÍCIO DE LANÇAMENTO',
                _endDate: 'DATA FIM LANÇAMENTO',
                _launchBy: 'LANÇADO POR',
                _status: 'ESTATUTO',
                _percentage: 'PERCENTAGEM',
                _actions: 'AÇÕES',
            },
            modulationHistory: {
                _launchBy: 'LANÇADO POR',
                _period: 'PERÍODO',
                _startDate: 'DATA INÍCIO OPERAÇÃO',
                _endDate: 'DATA FIM OPERAÇÃO',
                _status: 'ESTATUTO',
                _sheets: 'FOLHA',
            },
            excluderUser: {
                _matricule: 'MATRÍCULA',
                _firstname: 'APELIDO',
                _lastname: 'NOME',
                _email: 'EMAIL',
                _creationDate: 'DATA OPERAÇÃO',
                _entryDate: 'DATA ENTRADA',
                _actions: 'AÇÃO'
            },
            excluderSite: {
                _label: 'REDAÇÃO',
                _site: 'Sitio',
                _siteId: 'Sitio ID',
                _code: 'CÓDIGO',
                _sirh: 'CÓDIGO SIRH',
                _creationDate: 'DATA OPERAÇÃO',
                _actions: 'AÇÃO'
            }
        },
        payrollDetail: {
            _title: 'RESULTADO DO CÁLCULO DETALHADO',
            _validBtn: 'VALIDAR',
            _rejectBtn: 'REJEITAR',
            _exportJ: 'EXPORTAÇÃO DIÁRIA',
            _exportM: 'EXPORTAÇÃO MENSAL',
            _payExport: 'EXPORTAÇÃO VENCIMENTO',
            _excelExport: 'EXPORTAÇÃO EXCEL',
            _noDataFound: 'NENHUM RESULTADO ENCONTRADO',
            _attention: 'ATENÇÃO',
            _isValidated: 'QUER VALIDAR ESTE RESULTADO DE CÁLCULO?',
            _isRejected: 'QUER VALIDAR ESTE RESULTADO DE CÁLCULO?',
            _cancel: 'ANULAR',
            _reset: 'Reiniciar',
            _search: 'PESQUISAR',
            _period: 'PERÍODO',
            _year: 'ANO',
            _global: 'HORAS GLOBAIS'
        },
        launch: {
            _title: 'LANÇAMENTO DO CÁLCULO',
            _label: 'TÍTULO',
            _modulation: 'MODULAÇÃO',
            _country: 'PAÍS',
            _rules: 'REGULAMENTAÇÃO',
            _startDate: 'DATA VENCIMENTO INÍCIO',
            _endDate: 'DATA VENCIMENTO FIM',
            _periodicity: 'PERIODICIDADE',
            _validate: 'VALIDAR',
            _reset: 'Reiniciar',
            _textArea: 'INTRODUZIR AS MATRÍCULAS ...',
            _isRequired: 'Todos os campos são obrigatórios',
            _verify: 'Obrigado por ter verificado as matrículas introduzidas!',
        },
        history: {
            _title: 'HISTÓRICO MODULAÇÃO',
            _reset: 'REINICIAR',
            _search: 'PESQUISAR',
            _status: 'ESTATUTO',
            _dateDeb: 'DATA INÍCIO',
            _select: 'SELECIONAR',
            _dateFin: 'DATA FIM',
            _period: 'PERÍODO',
        },
        modulation: {
            _title: 'IMPORTAÇÃO  MODULAÇÃO',
            _history: 'HISTÓRICO',
            _reset: 'Reiniciar',
            _import: 'IMPORTAÇÃO',
            _msg: 'O ficheiro foi rejeitado. Só são suportados ficheiros EXCEL',
            _file: 'Deposite um ficheiro aqui ou clique',
            _year: 'ANO',
            _period: 'PERÍODO',
            _pending: 'OPERAÇÃO EM CURSO DE TRATAMENTO',
            _select_file: 'Seleccionar ficheiro',
            _date: 'Data',
            _type: 'Tipo'
        },
        userExclusion: {
            _title: 'parametrização dos UTILIZADORES EXCLUÍDOS',
            _agentSearch: 'Procurar um agente...',
            _add: 'ACRESCENTAR',
            _list: 'LISTA',
            _delete: 'REMOVER',
            _search: 'PESQUISAR',
        },
        siteExclusion: {
            _title: 'parametrização dos sites EXCLUÍDOS',
            _siteSearch: 'PROCURAR UM SITE...',
            _add: 'ACRESCENTAR',
            _list: 'LISTA',
            _delete: 'REMOVER',
            _search: 'PESQUISAR',
        },
        REGULATIONS: {
            LIST_REGULATION: {
                _TITLE: 'LISTA DE REGULAMENTOS',
            },
            NEW_REGULATION: {
                _TITLE: 'NOVO REGULAMENTO',
                _INIT_BUTTON: 'REINICIAR',
                _ADD_BUTTON: 'ADD',
                FIELDS: {
                    _COUNTRY_TYPE: 'TIPO DE PAÍS',
                    _DESCRIPTION: 'DESCRIÇÃO DO REGULAMENTO',
                    _NAME: 'NOME DO REGULAMENTO',
                    _CODE: 'CÓDIGO DE REGULAMENTO',
                    _LABEL: 'RÓTULO DO REGULAMENTO ',
                    _COUNTRIES: 'PAÍSES',
                }
            },
        },
        SERVERS: {
            _TITLE: 'LISTA DE SERVIDORES',
            COLUMNS: {
               _NAME: 'Nome',
                _IP: 'IP',
                _EXCEPTION: 'Exceção',
                _STATUS: 'Estado',
            },
            _ENABLED: 'FUNCIONAMENTO',
            _DISABLED: 'DOWN',
        },
        _isDeleted: 'DESEJA REMOVER O LANÇAMENTO ',
        _deleteUser: 'DESEJA REMOVER O COLABORADOR  ',
        _deleteSite: 'DESEJA REMOVER O SITE ',
        _engine: 'MOTOR DOS ELEMENTOS VARIÁVEIS',
        _status: 'Estatuto',
        _period: 'Período',
        _state: 'Estado de lançamento',
        _title: 'Título',
        _init: 'Reiniciar',
        _search: 'Pesquizar',
        _visualise: 'VISUALIZAR',
        _pending: 'EM CURSO DE PREPARAÇÃO',
        _delete: 'REMOVER',
        _deleted: 'REMOVIDO',
        _oui: 'Sim',
        _non: 'Não',
        _retry: 'RELANÇAR O CÁLCULO',
        _noDataFound: 'NENHUM RESULTADO ENCONTRADO',
        _validate: 'À ESPERA DE VALIDAÇÃO',
        _valid: 'VALIDADO',
        _reject: 'REJEITADO',
        _failed: 'FALHADO',
        _choixCompteur: 'CONTADORES POR POPULAÇÃO',
        _choixVariable: 'VARÁVEIS POR POPULAÇÃO',
        _updateTitle: 'MODIFICAR EVS ',
        _detailsTitle: 'DETALHES EVS ',
        _addTitle: 'ACRESCENTAR UM EV',
        _paramCompteur: 'CONTADOR',
        _myshiftModifs: 'MYSHIFT',
        _desc: '<p>O seu código de acesso pessoal permite-lhe proteger o acesso aos seus recibos de vencimento, especialmente quando são ' +
            'descarregados para computadores que podem ser utilizados por outros colaboradores.' +
            '<br/><br/> Recomendamos vivamente que guarde o seu código pessoal e assim proteja o acesso aos seus recibos de vencimento.</p>',
        _descIR: '<p>O seu código de acesso pessoal permite-lhe proteger o acesso às suas declarações de IRS, nomeadamente quando estas são ' +
            'descarregadas em computadores cuja utilização é possível por outros colaboradores.' +
            '<br/><br/> Recomendamos vivamente que guarde o seu código pessoal e assim proteja o acesso às suas declarações de IRS</p>',
        _salaryTitle: 'OS MEUS RECIBOS DE VENCIMENTO',
        _irsTitle: 'DECLARAÇÃO IRS',
        _downloadBtn: 'DOWNLOAD',
        _refreshBtn: 'REFRESCAR',
        _backBtn: 'VOLTAR',
        _securedPDF: 'Pdf seguro',
        _allStatements: 'TODOS OS RECIBOS ',
        _allIrs: 'TODAS AS DECLARAÇÕES IRS ',
        _statement: 'RECIBOS ',
        _irs: 'IR ',
        _warning: 'Os seus recibos de vencimento não serão protegidos por um código de acesso pessoal',
        _warningIR: 'As suas declarações de IRS não serão protegidas por um código de acesso pessoal',
        _continueBtn: 'CONTINUAR',
        _cancelBtn: 'ANULAR',
        _okBtn: 'CONFIRMAR',
        _JAN: 'JAN',
        _FEV: 'FEV',
        _MAR: 'MAR',
        _AVR: 'ABR',
        _MAI: 'MAI',
        _JUN: 'JUN',
        _JUL: 'JUL',
        _AOU: 'AGO',
        _SEP: 'SET',
        _OCT: 'OUT',
        _NOV: 'NOV',
        _DEC: 'DEZ',
        lang: "pt",
        _attention: 'ATENÇÃO',
        _code: 'CÓDIGO DE SEGURANÇA',
        _msg: 'Obrigado por indicar o seu código pessoal para proteger o seu recibo de vencimento',
        _msgIR: 'Obrigado por indicar o seu código pessoal para proteger a sua declaração de iRS',
        _submitBtn: 'VALIDAR',
        _notFound: 'Nenhum recibo de vencimento encontrado',
        _notFoundIR: 'Nenhuma declaração de IRS encontrada',
        _addCounter: 'ACRESCENTAR UM CONTADOR',
        _updateCounter: 'MODIFICAR O CONTADOR',
        _ramadanTitle: 'Adan Calendar',
        ramadan: {
            country: 'Country',
            city: 'City',
            specificationDay: 'Date',
            fajrTime: 'Fajr',
            sunRiseTime: 'Sunrise',
            dohrTime: 'Dhuhr',
            asrTime: 'Asr',
            maghrebTime: 'Maghrib',
            ishaTime: 'Isha',
            actions: 'Actions',
            cancel: 'Cancel'
        },
        _isRequired: 'Todos os campos são obrigatórios',
    },
    esp: {
        title: {
            _settings: "CONFIGURACIONES",
            _launch: "INICIO DE CÁLCULO",
            _periodicLaunch: "LANZAMIENTO PERIÓDICO",
            _global: "TOTAL DE HORAS",
            _modulation: "MODULACIÓN",
            _exclude: "CASOS EXCLUIDOS",
            _salary: "RECIBO DE NÓMINA",
            _payEngine: "MOTOR DE NÓMINA",
            _IR: "Declaraciones de IMPUESTOS",
            _TRANSLATE: 'Traducir',
            _themeChange: 'Cambiar el tema',
            _logOut: 'Desconectar',
            _USERS: 'USUARIOS',
            _SITES: 'SITIOS',
            REGULATIONS: 'REGLAMENTACIÓN',
            SERVERS: "SERVIDORES",
        },
        setting: {
            _active: "Activo",
            _deleted: "Eliminado",
            _settingTitle: "Configuración",
            _refWorkingHours: "Ref Horas de trabajo",
            _refModulationUser: "Ref Modulación Usuario",
            _modulation: "Modulación",
            _excludedCases: 'Casos Excluidos',
            _excludedUsers: 'Usuarios',
            _excludedSites: 'Sitios',
            _export: 'Exportar',
            columns: {
                _matricule: "Número de identificación del empleado",
                _startDate: "Fecha de inicio",
                _endDate: "Fecha de finalización",
                _creationDate: "Fecha de creación",
                _updatedDate: "Fecha de modificación",
                _month: "Mes",
                _year: "Año",
                _counter: "Contador",
                _user: "Usuario",
                _status: "Estado",
                _dateEffet: "Fecha de vigencia",
                _rule: "Reglamentación",
            }
        },
        columns: {
            payroll: {
                _title: "TÍTULO",
                _modulation: "MODULACIÓN",
                _period: "PERIODO",
                _startDate: "FECHA DE INICIO DEL LANZAMIENTO",
                _endDate: "FECHA DE FINALIZACIÓN DEL LANZAMIENTO",
                _launchBy: "LANZADO POR",
                _status: "ESTADO",
                _percentage: "PORCENTAJE",
                _actions: "ACCIONES",
            },
            modulationHistory: {
                _launchBy: "LANZADO POR",
                _period: "PERIODO",
                _startDate: "FECHA DE INICIO DE OPERACIÓN",
                _endDate: "FECHA FIN DE OPERACIÓN ",
                _status: "ESTADO",
                _sheets: "HOJA",
            },
            excluderUser: {
                _matricule: "Número de identificación del empleado",
                _firstname: "Apellido",
                _lastname: "Nome",
                _email: "Email",
                _creationDate: "Fecha de operación ",
                _entryDate: "Fecha de ingreso ",
                _actions: "Acción ",
            },
            excluderSite: {
                _label: "Título ",
                _site: 'Sitio',
                _siteId: 'Sitio ID',
                _code: "Código",
                _sirh: "Código SIRH",
                _creationDate: "Fecha de operación ",
                _actions: "Acción ",
            }
        },
        payrollDetail: {
            _title: "DETALLES DE LOS RESULTADOS DEL CÁLCULO",
            _validBtn: "APROBAR",
            _rejectBtn: "RECHAZAR",
            _exportJ: "EXPORTE DIARIO",
            _exportM: "EXPORTE MENSUAL ",
            _payExport: "EXPORTE DE NOMINA ",
            _excelExport: "EXPORTE DE EXCEL ",
            _noDataFound: "RESULTADOS NO ENCONTRADOS",
            _attention: "PRECAUCIÓN ",
            _isValidated: "¿DESEA APROBAR EL RESULTADO DE ESTE CÁLCULO?",
            _isRejected: "¿DESEA RECHAZAR EL RESULTADO DE ESTE CÁLCULO?",
            _cancel: "CANCELAR",
            _reset: "RESTABLECER",
            _search: "BUSCAR",
            _period: "PERIODO",
            _year: "AÑO",
            _global: "TOTAL DE HORAS",
        },
        launch: {
            _title: "INICIO DE CÁLCULO",
            _label: "TÍTULO ",
            _modulation: "MODULACIÓN",
            _country: "PAÍS",
            _rules: "REGULACIÓN",
            _startDate: "FECHA DE INICIO DE LA NÓMINA",
            _endDate: "FECHA FIN DE LA NÓMINA",
            _periodicity: "PERIODICIDAD ",
            _validate: "APROBAR",
            _reset: "RESTABLECER",
            _textArea: "INGRESE EL NUMERO DE IDENTIFICACIÓN DE EMPLEADO",
            _isRequired: "Todos los campos son obligatorios",
            _verify: "Compruebe el número de empleado que ha registrado.",
        },
        history: {
            _title: 'HISTORIAL MODULACIÓN',
            _select: 'SELECCIONAR',
            _status: 'ESTADO',
            _dateDeb: 'FECHA INICIO',
            _dateFin: 'FECHA FINALIZACIÓN',
            _period: 'FECHA FINALIZACIÓN',
            _reset: 'REINICIAR',
            _search: 'BUSCAR',
        },
        modulation: {
            _title: "HISTORIA DE MODULACIÓN",
            _reset: "RESTABLECER",
            _import: 'IMPORTAR',
            _search: "BUSCAR",
            _status: "ESTADO",
            _dateDeb: "FECHA DE INICIO ",
            _select: "SELECCIONAR",
            _dateFin: "FECHA FIN ",
            _period: "PERIODO",
            _pending: 'OPERACIÓN EN CURSO',
            _select_file: 'SELECCIONAR un archivo',
            _date: 'Fecha',
            _type: 'Tipo'
        },
        userExclusion: {
            _title: "CONFIGURACIÓN DE USUARIOS EXCLUIDOS ",
            _agentSearch: "Buscar a un agente",
            _add: "AGREGAR",
            _list: "LISTA",
            _delete: "ELIMINAR",
            _search: "BUSCAR",
        },
        siteExclusion: {
            _title: "CONFIGURACIÓN DE SITE EXCLUIDOS",
            _siteSearch: "BUSCAR UN SITE",
            _add: "AGREGAR",
            _list: "LISTA",
            _delete: "ELIMINAR",
            _search: "BUSCAR",
        },
        REGULATIONS: {
            LIST_REGULATION: {
                _TITLE: 'LISTA DE REGULACIONES',
            },
            NEW_REGULATION: {
                _TITLE: "NUEVA REGULACIÓN",
                _INIT_BUTTON: "RESTABLECER",
                _ADD_BUTTON: "AGREGAR",
                FIELDS: {
                    _COUNTRY_TYPE: "TIPO DE PAÍS",
                    _DESCRIPTION: "DESCRIPCIÓN DE REGULACIÓN ",
                    _NAME: "NOMBRE DE REGULACIÓN ",
                    _CODE: "CODIGO DE REGULACIÓN ",
                    _LABEL: "NIVEL DE REGULACIÓN ",
                    _COUNTRIES: "PAÍSES",
                }
            },
        },
        SERVERS: {
            _TITLE: 'LISTA DE SERVIDORES',
            COLUMNS: {
                _NAME: "NIVEL",
                _IP: "IP",
                _EXCEPTION: "EXCEPCIONES ",
                _STATUS: "ESTADO",
            },
            _ENABLED: "",
            _DISABLED: "",
        },
        _isDeleted: "¿DESEA ELIMINAR EL LANZAMIENTO?",
        _deleteUser: "¿DESEA ELIMINAR EL EMPLEADO?",
        _deleteSite: "¿DESEA ELIMINAR EL SITE?",
        _engine: "MOTOR DE ELEMENTOS VARIABLES",
        _status: "ESTADO",
        _period: "PERIODO",
        _state: "ESTADO DE LANZAMIENTO ",
        _title: "TIÍTULO",
        _init: "RESTABLECER",
        _search: "BUSCAR",
        _visualise: "VISTA",
        _pending: "EN PREPARACIÓN",
        _delete: "ELIMINAR ",
        _deleted: "Eliminado",
        _oui: 'Sí',
        _non: 'No',
        _retry: "REINICIAR EL CÁLCULO",
        _noDataFound: "RESULTADO NO ENCONTRADO",
        _validate: "PENDIENTE DE APROBACIÓN ",
        _valid: "APROBADO",
        _reject: "RECHAZADO",
        _failed: "FALLÓ",
        _choixCompteur: "",
        _choixVariable: "",
        _updateTitle: "",
        _detailsTitle: "",
        _addTitle: "",
        _paramCompteur: "",
        _myshiftModifs: "MI TURNO",
        _desc: "",
        _descIR: "",
        _salaryTitle: "MIS RECIBO DE NÓMINA",
        _irsTitle: "",
        _downloadBtn: "DESCARGA",
        _refreshBtn: "REFRESCAR",
        _backBtn: "ATRÁS",
        _securedPDF: "PDF Protegido ",
        _allStatements: "TODOS LOS RECIBOS DE NÓMINA",
        _allIrs: "Declración de Impuestos",
        _statement: "RECIBO DE NÓMINA",
        _irs: "IMPUESTOS",
        _warning: "Sus recibos de nóminas no estarán protegidos por un código de acceso personal",
        _warningIR: "Sus declaraciones de impuestos no estarán protegidas por un código de acceso personal",
        _continueBtn: "CONTINUAR",
        _cancelBtn: "CANCELAR",
        _okBtn: "CONFIRMAR",
        _JAN: "ENERO",
        _FEV: "FEBRERO",
        _MAR: "MARZO",
        _AVR: "ABRIL ",
        _MAI: "MAYO",
        _JUN: "JUNIO",
        _JUL: "JULIO",
        _AOU: "AGOSTO",
        _SEP: "SEPTIEMBRE",
        _OCT: "OCTUBRE",
        _NOV: "NOVIEMBRE",
        _DEC: "DICIEMBRE",
        lang: "",
        _attention: "ATENCIÓN",
        _code: "CODIGO DE SEGURIDAD",
        _msg: "",
        _msgIR: "",
        _submitBtn: "APROBAR",
        _notFound: "RECIBO DE NÓMINA NO ENCONTRADO",
        _notFoundIR: "DECLARACIÓN DE IMPUESTOS NO ENCONTRADA",
        _addCounter: "",
        _updateCounter: "",
        _ramadanTitle: "",
        ramadan: {
            country: 'Pays',
            city: 'Ville',
            specificationDay: 'Date',
            fajrTime: 'Fajr',
            sunRiseTime: 'Lever du soleil',
            dohrTime: 'Dohr',
            asrTime: 'Asr',
            maghrebTime: 'Maghreb',
            ishaTime: 'Isha',
            actions: 'Actions',
            cancel: 'Fermer'
        },
        _isRequired: 'Tous les champs sont obligatoires',
    }
};
export let strings = stringsLangs.fr;

export const setLanguage = (countryCode) => {
    if (countryCode === "PT") {
        strings = stringsLangs.pt;
        return stringsLangs.pt;
    } else if (countryCode === "EN") {
        strings = stringsLangs.en;
        return stringsLangs.en;
    } else if (countryCode === "ES") {
        strings = stringsLangs.esp;
        return stringsLangs.esp;
    } else {
        strings = stringsLangs.fr;
        return stringsLangs.fr;
    }
};
