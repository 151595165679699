export const GET_ALL_POP = 'GET_ALL_POP';
export const GET_ALL_POP_SUCCESS = 'GET_ALL_POP_SUCCESS';
export const GET_ALL_POP_FAIL = 'GET_ALL_POP_FAIL';
export const GET_ALL_COUNTRIES = 'GET_ALL_COUNTRIES';
export const GET_ALL_COUNTRIES_SUCCESS = 'GET_ALL_COUNTRIES_SUCCESS';
export const GET_ALL_COUNTRIES_FAIL = 'GET_ALL_COUNTRIES_FAIL';
export const GET_ALL_EVS = 'GET_ALL_EVS';
export const GET_ALL_EVS_SUCCESS = 'GET_ALL_EVS_SUCCESS';
export const GET_ALL_EVS_FAIL = 'GET_ALL_EVS_FAIL';
export const SET_PAGES = 'SET_PAGES';
export const REMOVE_EVS = 'REMOVE_EVS';
export const SAVE_EVS = 'SAVE_EVS';
export const SAVE_EVS_FAIL = 'SAVE_EVS_FAIL';
export const SAVE_EVS_SUCCESS = 'SAVE_EVS_SUCCESS';
export const GET_DATA = 'GET_DATA';
export const INIT_DATA = 'INIT_DATA';
export const SET_CRITERIA = 'SET_CRITERIA';
export const EXPORT_EVS = 'EXPORT_EVS';
export const GET_USERS_UOs_SUCCESS = 'GET_USERS_UOs_SUCCESS';
export const GET_USERS_UOs_FAIL = 'GET_USERS_UOs_FAIL';
export const EXPORT_EVS_FAIL = 'EXPORT_EVS_FAIL';
export const GET_ALL_UOs_SUCCESS = 'GET_ALL_UOs_SUCCESS';
export const GET_ALL_UOs_FAIL = 'GET_ALL_UOs_FAIL';

export const GET_COUNTRY_TYPES_SUCCESS = 'GET_COUNTRY_TYPES_SUCCESS';
export const GET_COUNTRY_TYPES_FAIL = 'GET_COUNTRY_TYPES_FAIL';

