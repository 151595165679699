import {
    GET_REF_MODULATION_USER,
    GET_REF_MODULATION_USER_SUCCESS,
    GET_REF_MODULATION_USER_FAIL,
    GET_REF_WORKING_HOURS_SUCCESS,
    GET_REF_WORKING_HOURS_FAIL,
    GET_REF_WORKING_HOURS,
    GET_MODULATION,
    GET_MODULATION_SUCCESS,
    GET_MODULATION_FAIL,
    EXPORT_REF_MODULATION_USER_SUCCESS,
    EXPORT_REF_MODULATION_USER,
    EXPORT_REF_MODULATION_USER_FAIL,
    EXPORT_REF_WORKING_HOURS_USER,
    EXPORT_REF_WORKING_HOURS_SUCCESS,
    EXPORT_REF_WORKING_HOURS_FAIL,
    EXPORT_MODULATION,
    EXPORT_MODULATION_SUCCESS,
    EXPORT_MODULATION_FAIL,
    GET_EXCLUDED_USERS,
    GET_EXCLUDED_USERS_SUCCESS,
    GET_EXCLUDED_USERS_FAIL,
    EXPORT_EXCLUDED_USERS,
    EXPORT_EXCLUDED_USERS_SUCCESS,
    EXPORT_EXCLUDED_USERS_FAIL,
    GET_EXCLUDED_SITES,
    GET_EXCLUDED_SITES_SUCCESS,
    GET_EXCLUDED_SITES_FAIL,
    EXPORT_EXCLUDED_SITES,
    EXPORT_EXCLUDED_SITES_SUCCESS,
    EXPORT_EXCLUDED_SITES_FAIL
} from "../types/SettingTypes";

const initialState = {
    loading: false,
    refModulationData: [],
    refModulationPages: 0,
    totaRefModulationElements: 0,
    refWorkingHoursData: [],
    refWorkingHoursPages: 0,
    totalRefWorkingHoursElements: 0,
    modulationData: [],
    modulationPages: 0,
    totalModulationElements: 0,
    error: null,
    isExporting: false
};

const settingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REF_MODULATION_USER:
            return { ...state, loading: true, isExporting : false };
        case GET_REF_MODULATION_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                refModulationData: action.result.content,
                totalModulationElements: action.result.totalElements,
                refModulationPages: action.result.totalPages,
            };
        case GET_REF_MODULATION_USER_FAIL:
            return { ...state, loading: false, refModulationData: [],refModulationPages:0,totalModulationElements:0, error: action.error };
        case EXPORT_REF_MODULATION_USER:
            return { ...state, isExporting : true};
        case EXPORT_REF_MODULATION_USER_SUCCESS:
            return { ...state, isExporting : false};
        case EXPORT_REF_MODULATION_USER_FAIL:
            return { ...state, isExporting : false};

        case GET_REF_WORKING_HOURS:
            return { ...state, loading: true, isExporting : false  };
        case GET_REF_WORKING_HOURS_SUCCESS:
            return {
                ...state,
                loading: false,
                refWorkingHoursData: action.result.content,
                totalRefWorkingHoursElements: action.result.totalElements,
                refWorkingHoursPages: action.result.totalPages,
            };
        case GET_REF_WORKING_HOURS_FAIL:
            return { ...state, loading: false, refWorkingHoursData: [],refWorkingHoursPages: 0,totalRefWorkingHoursElements: 0, error: action.error };
        case EXPORT_REF_WORKING_HOURS_USER:
            return { ...state, isExporting : true};
        case EXPORT_REF_WORKING_HOURS_SUCCESS:
            return { ...state, isExporting : false};
        case EXPORT_REF_WORKING_HOURS_FAIL:
            return { ...state, isExporting : false};


        case GET_MODULATION:
            return { ...state, loading: true, isExporting : false };
        case GET_MODULATION_SUCCESS:
            return {
                ...state,
                loading: false,
                modulationData: action.result ? action.result.content : action.data,
                totalModulationElements: action.result ? action.result.totalElements : (action.data || []).length,
                modulationPages: action.result ? action.result.totalPages : (action.data || []).length,
            };
        case GET_MODULATION_FAIL:
            return { ...state, loading: false, modulationData: [],modulationPages: 0,totalModulationElements: 0, error: action.error };
        case EXPORT_MODULATION:
            return { ...state, isExporting : true};
        case EXPORT_MODULATION_SUCCESS:
            return { ...state, isExporting : false};
        case EXPORT_MODULATION_FAIL:
            return { ...state, isExporting : false};

        case GET_EXCLUDED_USERS:
            return { ...state, loading: true, isExporting : false };
        case GET_EXCLUDED_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                excludedUsersData: action.result.content,
                totalModulationElements: action.result.totalElements,
                excludedUsersPages: action.result.totalPages,
            };
        case GET_EXCLUDED_USERS_FAIL:
            return { ...state, loading: false, excludedUsersData: [],excludedUsersPages: 0,totalExcludedUsersElements: 0, error: action.error };
        case EXPORT_EXCLUDED_USERS:
            return { ...state, isExporting : true};
        case EXPORT_EXCLUDED_USERS_SUCCESS:
            return { ...state, isExporting : false};
        case EXPORT_EXCLUDED_USERS_FAIL:
            return { ...state, isExporting : false};

        case GET_EXCLUDED_SITES:
            return { ...state, loading: true, isExporting : false };
        case GET_EXCLUDED_SITES_SUCCESS:
            return {
                ...state,
                loading: false,
                excludedSitesData: action.result.content,
                totalModulationElements: action.result.totalElements,
                excludedSitesPages: action.result.totalPages,
            };
        case GET_EXCLUDED_SITES_FAIL:
            return { ...state, loading: false, excludedSitesData: [],excludedSitesPages: 0,totalExcludedSitesElements: 0, error: action.error };
        case EXPORT_EXCLUDED_SITES:
            return { ...state, isExporting : true};
        case EXPORT_EXCLUDED_SITES_SUCCESS:
            return { ...state, isExporting : false};
        case EXPORT_EXCLUDED_SITES_FAIL:
            return { ...state, isExporting : false};

        default:
            return state;
    }
};

export default settingReducer;
