import React, {useEffect} from 'react';
import axios from 'axios';
import {
    Tooltip,
    Paper,
    Tab,
    Box,
    withStyles,
    Select,
    MenuItem,
    Button,
    CircularProgress,
    input,
    FormControl,
    InputLabel
} from "@material-ui/core";

import CloudUpload from "@material-ui/icons/CloudUpload";

import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import VirtualizedTable from './VirtualizedTable';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {ThemeContext} from "../../App";
import {addExcludedUsers, getAllSites, getAllUsers} from "../../actions/ExcludeActions";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {strings} from "../../i18n/Strings";
import {connect, useDispatch} from "react-redux";
import {reduxForm} from "redux-form";
import {styles} from "../../styles/CommonStyles";
import {KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardTimePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns/build/index";
import {API} from "../../api/Endpoints";
import {getAxios, getAxiosWithHeaders, getFromSession} from "../../actions/AuthActions";
import './PreparePay.css';

export const useStyles = makeStyles((theme) => ({
    rooot: {
        '& .Mui-selected': {
            color: '#fff',
            background: 'linear-gradient(-45deg, #2F80ED, #56CCF2) !important',
        }
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    dialog: {
        position: 'absolute',
        top: 1
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    buttonUploadFile: {
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: '1px',
        borderColor: '#d1d1d1',
        backgroundColor: '#fff',
        marginTop: '6px'
    }
}));

const PreparePay = ({
                        classes, loading, users, inputVal, usersPage, sitesPage, success, totalUsersElements
                    }) => {

    const TYPE_MODULISATION = ['ALL', "MODULATION_USER", "MODULATION", "HEURS_TRAVALE"];
    const [inputValue, setInputValue] = React.useState(new Date().toString());
    const [inputDateValue, setInputDateValue] = React.useState(new Date());
    const [inputType, setType] = React.useState("ALL");
    const [workSheet, setWorkSheet] = React.useState([]);
    const [inputFile, setInputFile] = React.useState();
    const [loader, setLoader] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState(false);

    const stepMessage = {
        UPLOAD: "upload %s%",
        PROCESSING: 'Traitement du fichier',
        SLOW_REQUEST: "Veuillez patienter quelques seconds s'il vous plait"
    };

    const [valueTab, setValueTab] = React.useState('1');


    const classess = useStyles();
    const {_title, _date, _select_file, _import, _type} = strings.modulation;
    const dispatch = useDispatch();

    useEffect(() => {
        setMessage(stepMessage.UPLOAD)
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (ev, reason) => {
        if (reason && reason == "backdropClick")
            return;
        setOpen(false);
    };
    const StatusInject = ({value, message}) => {
        return message ? <Tooltip placement={"right"} title={message}>
            <div className={'status-' + String(value).toLowerCase()}>
                {value}
            </div>
        </Tooltip> : <div className={'status-' + String(value).toLowerCase()}>
            {value}
        </div>;
    }
    const updateStatus = (_workSheet, res) => {
        const data = res.data || {};
        Object.keys(data).forEach((key) => {
            (_workSheet[key] || []).forEach((row, i) => {
                const resRow = (data[key]);
                const m1 = (resRow['MYSIFT1'] || []).find(e => e.registrationNumber == row['Matricule']);
                const m2 = (resRow['MYSIFT2'] || []).find(e => e.registrationNumber == row['Matricule']);


                _workSheet[key][i]['status_m1'] = m1 ? m1.status : 'NA';
                _workSheet[key][i]['status_m2'] = m2 ? m2.status : 'NA';

                _workSheet[key][i]['My Shift1'] =
                    <StatusInject value={m1 ? m1.status : 'NA'} message={m1 ? m1.message : null}/>;
                _workSheet[key][i]['My Shift2'] =
                    <StatusInject value={m2 ? m2.status : 'NA'} message={m2 ? m2.message : null}/>;
            });
        });
        setWorkSheet(_workSheet)
    }

    const getColumns = (e) => {
        const cols = workSheet[e][0] ? Array.from(new Set(['My Shift1', 'My Shift2', ...Object.keys((workSheet[e][0]))])).map(col => ({
            width:
                ['my shift1', 'my shift2'].includes(String(col).toLowerCase()) ? 90 :
                    (String(col).toLowerCase() == 'nom' ? 400 : 200),
            label: col,
            dataKey: col,
        })) : [];
        return cols;
    }

    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };
    const rows = [];
    const sample = [
        ['Frozen yoghurt', 159, 6.0, 24, 4.0],
        ['Ice cream sandwich', 237, 9.0, 37, 4.3],
        ['Eclair', 262, 16.0, 24, 6.0],
        ['Cupcake', 305, 3.7, 67, 4.3],
        ['Gingerbread', 356, 16.0, 49, 3.9],
    ];

    const createData = function (
        id,
        dessert,
        calories,
        fat,
        carbs,
        protein,
    ) {
        return {id, dessert, calories, fat, carbs, protein};
    }
    for (let i = 0; i < 200; i += 1) {
        const randomSelection = sample[Math.floor(Math.random() * sample.length)];
        rows.push(createData(i, ...randomSelection));
    }

    const xlsxToJson = (worksheet) => {
        const data = worksheet.SheetNames.map((e, i) => {
            return [e, window.XLSX.utils.sheet_to_row_object_array(worksheet.Sheets[e])]
        })
        return Object.fromEntries(data);
    };

    const getStatusInject = (status) => {
        const key = getNameSheetByIndex(valueTab);
        const sheet = (workSheet[key] || []);
        console.log('getStatusInject:', {sheet})
        return sheet.filter((row, i) => {
            const m1 = row['My Shift1'] && typeof row['My Shift1'] != 'string' ? row['My Shift1'] : {props: {value: ''}};
            const m2 = row['My Shift2'] && typeof row['My Shift2'] != 'string' ? row['My Shift2'] : {props: {value: ''}};

            if (i < 10) console.log('======================>', {m1, m2})
            return row['My Shift1'] && row['My Shift2'] && (m1.props.value).toLowerCase() == String(status).toLowerCase() ||
                (m2.props.value).toLowerCase() == String(status).toLowerCase()
        }).length;
    }

    const hasNotInjected = () => {
        const _worksheet = {...workSheet};
        return Object.keys(_worksheet).filter((key) => {
            let rows = _worksheet[key];
            rows = rows.filter(e => {
                // const m1 = e['status_m1'] ? String(e['status_m1']).toLowerCase() != 'ok' : false;
                const m2 = e['status_m2'] ? String(e['status_m2']).toLowerCase() != 'ok' : false;
                return m2;
            }).length;
            return rows > 0
        }).length;
    }

    const ExportDataNoInjected = (_worksheet) => {
        console.log({workSheet, inputFile})
        const workbook = window.XLSX.utils.book_new();
        Object.keys(_worksheet).forEach((key) => {
            let rows = _worksheet[key];
            rows = rows
                .filter(e => {
                    const m1 = e['status_m1'] ? String(e['status_m1']).toLowerCase() != 'ok' : false;
                    const m2 = e['status_m2'] ? String(e['status_m2']).toLowerCase() != 'ok' : false;
                    return m1 || m2;
                })
                .map(e => {
                    delete e['status_m1'];
                    delete e['status_m2'];
                    delete e['My Shift1'];
                    delete e['My Shift2'];
                    return e;
                })
            const worksheet = window.XLSX.utils.json_to_sheet(rows);
            window.XLSX.utils.book_append_sheet(workbook, worksheet, key);

            /* fix headers */
            window.XLSX.utils.sheet_add_aoa(worksheet, [Object.keys(rows[0] || {})], {origin: "A1"});

            const max_width = rows.reduce((w, r) => Math.max(w, Object.keys(r)[0].length), 10);
            worksheet["!cols"] = [{wch: max_width}];
            /* create an XLSX file and try to save to Presidents.xlsx */
        })
        window.XLSX.writeFile(workbook, String(inputFile.name).replace(new RegExp('list_not_injected', 'gm'), '')
            .replace('.xlsx', '_list_not_injected.xlsx'));
    }
    const importModulisation = () => {
        const d1 = new Date();
        handleClickOpen();
        let timeout;
        const date = inputDateValue;
        console.log('inputDateValue:', inputDateValue, '=')
        const frm = new FormData();
        frm.append("file", inputFile);
        frm.append("year", date.getFullYear());
        frm.append("month", date.getMonth() + 1);
        frm.append("type", inputType);
        frm.append("uploadedBy", "mypay");

        const url = `${API}/api/paie/upload`;
        const s = new URLSearchParams();
        setMessage(stepMessage.UPLOAD.replace("%s", 0))

        const $access_token = getFromSession('ACCESS_TOKEN');
        const token1 = $access_token.substring(0, $access_token.length / 2);
        const token2 = $access_token.substring($access_token.length / 2, $access_token.length);
        setMessage(stepMessage.PROCESSING);
        if (loader) {
            timeout = setTimeout(() => {
                setMessage(stepMessage.SLOW_REQUEST);
            }, 30000)
        }
        axios.post(`${url}`, frm, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Intelcia1: token1,
                Intelcia2: token2
            },
            onUploadProgress: progressEvent => {
                /*const count = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2);
                if (count != 100) {
                    setMessage(stepMessage.UPLOAD.replace("%s", count))
                } else {
                    if (loader) {
                        setMessage(stepMessage.PROCESSING);
                        timeout = setTimeout(() => {
                            setMessage(stepMessage.SLOW_REQUEST);
                        }, 30000)
                    }
                }*/
            }
        }).then((res) => {
            // update status
            handleClose();
            setLoader(false);

            updateStatus({...workSheet}, res);
            /*
                        ({
                            "Le temps contractuel": {
                                "MYSIFT2": [
                                    {
                                        "registrationNumber": "000164",
                                        "message": "test",
                                        "status": "OK"
                                    }
                                ],
                                "MYSIFT1": [
                                    {
                                        "registrationNumber": "000164",
                                        "message": "",
                                        "status": "ERROR"
                                    }
                                ]
                            }
                        })
            */
        }, reason => {
            // erro
            setMessage(reason.toString());
            setLoader(false);
        }, () => {
            if (timeout) clearTimeout(timeout);
        })

        /*
                updateStatus({...workSheet}, {
                    data: {
                        "Le temps contractuel": {
                            "MYSIFT2": [
                                {
                                    "registrationNumber": "000164",
                                    "message": "test",
                                    "status": "OK"
                                }
                            ],
                            "MYSIFT1": [
                                {
                                    "registrationNumber": "000164",
                                    "message": "",
                                    "status": "ERROR"
                                }
                            ]
                        }
                    }
                })
        */
    }

    const getNameSheetByIndex = (index) => {
        return Object.keys(workSheet)[index];
    }

    const getSheetByIndex = (index) => {
        return (workSheet[getNameSheetByIndex(index)] || []);
    }

    return (
        <ThemeContext.Consumer>
            {context => (
                <>
                    <div style={{height: 'calc(100vh - 90px)', overflow: 'hidden', padding: '0 25px'}}>
                        <h3 style={{
                            marginTop: 1,
                            color: context.selectedTheme.color,
                            fontWeight: 'normal'
                        }}>{_title}</h3>
                        <hr color="#f5f5f5"/>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'calc(100vh - 115px)',
                            justifyContent: 'center'
                        }}>
                            <div style={{
                                maxHeight: "50px",
                                minHeight: "50px",
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '10px'
                            }}>
                                <div style={{flex: 1, transform: 'scale(.85)'}}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            views={["year", "month"]}
                                            inputVariant="outlined"
                                            disableToolbar
                                            format="MM/yyyy"
                                            fullWidth
                                            margin="normal"
                                            id="date-picker-inline"
                                            label={_date}
                                            onChange={(value) => {
                                                setInputDateValue(new Date(value));
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'changer la date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>

                                <div style={{marginTop: 6, flex: 1, transform: 'scale(.85)'}}>
                                    <FormControl variant="outlined" style={{width: '100%'}}>
                                        <InputLabel id="demo-simple-select-standard-label">{_type}</InputLabel>
                                        <Select
                                            style={{flex: 1, width: '100%'}}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={inputType}
                                            label={_type}
                                            onChange={(ev, value) => {
                                                setType(ev.target.value);
                                            }}
                                        >
                                            {TYPE_MODULISATION.map((v) => {
                                                return <MenuItem value={v}>{v}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>

                                </div>

                                <div style={{flex: 1, transform: 'scale(.85)'}}>
                                    <Button
                                        className={classess.buttonUploadFile}
                                        style={{width: 'calc(100%)', height: '50px'}}
                                        variant="outlined"
                                        component="label"
                                    >
                                        {!inputFile && <div style={{marginRight: 10}}>
                                            <CloudUpload/>
                                        </div>}
                                        {!inputFile ? _select_file : inputFile.name}
                                        <input
                                            type="file"
                                            hidden
                                            accept=".xlsx,.xls"
                                            onChange={async (ev) => {
                                                if (ev.target.files.length) {
                                                    const [file] = ev.target.files;
                                                    if (file) {
                                                        const arrayBuf = await file.arrayBuffer()
                                                        const wb = window.XLSX.read(arrayBuf);
                                                        const w = xlsxToJson(wb);
                                                        setWorkSheet(w);
                                                        setInputFile(file);
                                                        setValueTab(0);
                                                    }
                                                }
                                            }}
                                        />
                                    </Button>
                                </div>
                                <div style={{flex: 1, transform: 'scale(.85)', display: 'flex', flexDirection: 'row'}}>
                                    <Button

                                        size={"large"}
                                        style={{
                                            width: 'calc(100%)',
                                            background: loader ? '#bbb' : inputFile ? context.selectedTheme.color : 'transparent',
                                            color: (inputFile || loader) ? '#fff' : '#bbb',
                                            borderColor: 'currentColor'
                                        }}
                                        variant="outlined"
                                        color="success"
                                        onClick={() => {
                                            setLoader(!loader);
                                            importModulisation();
                                        }}
                                        disabled={!inputFile || loader}>

                                        {loader && <CircularProgress size={24}/>}
                                        {!loader && _import}
                                    </Button>

                                    {(hasNotInjected() > 0) && <Button

                                        size={"large"}
                                        style={{
                                            width: 'calc(100%)',
                                            background: '#ee5452',
                                            color: '#fff',
                                            borderColor: 'currentColor'
                                        }}
                                        variant="outlined"
                                        color="success"
                                        onClick={() => {
                                            ExportDataNoInjected(workSheet);
                                        }}
                                        disabled={!inputFile || loader}>
                                        {hasNotInjected()} not injected
                                    </Button>}

                                </div>

                            </div>
                            <div style={{
                                flex: 1,
                                overflow: 'auto',
                                maxHeight: 'calc(100vh - 90px)',
                                transition: 'all .5s',
                            }} className={"scroll"}>
                                <Box sx={{width: '100%', typography: 'body1'}}>
                                    <TabContext value={valueTab}>


                                        {Object.keys(workSheet).map((e, i) => {
                                            return <TabPanel style={{padding: 0}} value={i}>
                                                <Paper style={{height: 'calc(100vh - 280px)', width: '100%'}}>
                                                    <VirtualizedTable
                                                        rowCount={workSheet[e].length}
                                                        rowGetter={({index}) => {
                                                            const c = workSheet[e][index]
                                                            c['My Shift1'] = c['My Shift1'] || '-';
                                                            c['My Shift2'] = c['My Shift2'] || '-';
                                                            return c
                                                        }}
                                                        columns={getColumns(e)}
                                                    />
                                                </Paper>
                                            </TabPanel>
                                        })}

                                        {!!getSheetByIndex(valueTab).length && <div style={{
                                            minHeight: '30px',
                                            alignItems: 'center',
                                            background: '#eee',
                                            padding: '0 20px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <div className={"record-item record-ok"}>
                                                    Record NA: {getStatusInject("na")}
                                                </div>
                                                <div className={"record-item record-ok"}>
                                                    Record OK: {getStatusInject("ok")}
                                                </div>
                                                <div className={"record-item record-ok"}>
                                                    Record ERR: {getStatusInject("error")}
                                                </div>
                                            </div>
                                            <div>
                                                <div className={"record-item record"}>
                                                    Record: {getSheetByIndex(valueTab).length}
                                                </div>
                                            </div>

                                        </div>}

                                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                                {Object.keys(workSheet).map((e, i) => {
                                                    return <Tab label={e}
                                                                value={i}/>
                                                })}
                                            </TabList>
                                        </Box>

                                    </TabContext>
                                </Box>
                            </div>
                        </div>

                    </div>


                    <Dialog
                        fullWidth={true}
                        maxWidth={'sm'}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {message == stepMessage.SLOW_REQUEST && <CircularProgress size={15}/>}
                                {message}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary" autoFocus>
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </ThemeContext.Consumer>
    );

};

const mapStateToProps = state => {
    return {
        loading: state.exclusReducer.loading,
        users: state.exclusReducer.users,
        totalUsersElements: state.exclusReducer.totalUsersElements,
        usersPage: state.exclusReducer.usersPage,
        inputVal: state.exclusReducer.inputVal,
        success: state.exclusReducer.success,
        lang: state.salaryReducer.lang
    };
};
export default connect(mapStateToProps)(reduxForm({
    form: 'PreparePay',
    enableReinitialize: true
})(withStyles(styles)(PreparePay)));
