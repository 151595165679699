import React, {Fragment, useState} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DvrIcon from '@material-ui/icons/Dvr';
import AlarmIcon from '@material-ui/icons/Alarm';
import List from '@material-ui/core/List';
import {Link} from 'react-router-dom';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {strings} from "../i18n/Strings";
import {connect} from "react-redux";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TableChartIcon from '@material-ui/icons/TableChart';
import PagesIcon from '@material-ui/icons/Pages';
import {hasAuthority} from "../actions/AuthActions";
import {Collapse, Divider} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import DnsIcon from '@material-ui/icons/Dns';
import ExtensionIcon from '@material-ui/icons/Extension';

const noUnderLine = {textDecoration: 'none'};

function isSelected(selected, path) {
    return selected === path ? {backgroundColor: "#e5e5e5"} : {};
}

export const NavItem = props => {
    const {path, icon, title, selected, color} = props;
    const {_USERS,_SITES} = strings.title;
    const listRegulationsTitle = strings.REGULATIONS.LIST_REGULATION._TITLE;
    const {_TITLE} = strings.REGULATIONS.NEW_REGULATION;
    const [openCollapse, setOpenCollapse] = useState(false);
    const styleListItem = Object.assign(isSelected(selected, path), {
        borderBottom: '1px solid #e4e5e7',
        padding: 15,
        color: '#000'
    });
    return <Fragment>
        {
            path === "modulation" ?
                <>
                    <ListItem style={styleListItem} button
                              onClick={() => {
                                  setOpenCollapse(prevState => !prevState);
                              }}>
                        {
                            <ListItemIcon>
                                {icon}
                            </ListItemIcon>
                        }
                        <ListItemText style={{
                            paddingTop: 10
                        }}
                                      primary={<span style={{
                                          fontFamily: 'Varela Round',
                                          fontSize: 13,
                                          color: '#545454'
                                      }}>{title}</span>}
                        />
                        {openCollapse ? <ExpandLessIcon style={{color: color, marginTop: 8}}/>
                            : <ExpandMoreIcon style={{color: color, marginTop: 8}}/>}
                    </ListItem>
                    <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Link to={'modulation'} style={noUnderLine}>
                                <ListItem style={styleListItem && {paddingLeft: 54}} button
                                          onClick={() => {
                                              props.onSelect(path)
                                          }}>
                                    {/*{*/}
                                    {/*    <ArrowForwardIosIcon style={{color: color, transform: 'scale(0.6)', marginTop: 8}}/>*/}
                                    {/*}*/}
                                    <ListItemText style={{
                                        paddingTop: 10,
                                        paddingLeft: 17
                                    }}
                                                  primary={<span style={{
                                                      fontFamily: 'Varela Round',
                                                      fontSize: 13,
                                                      color: '#545454'
                                                  }}>IMPORT</span>}
                                    />

                                </ListItem>
                            </Link>
                            <Divider/>
                            <Link to={'mod_history'} style={noUnderLine}>
                                <ListItem style={styleListItem && {paddingLeft: 54}} button
                                          onClick={() => {
                                              props.onSelect(path)
                                          }}>
                                    {/*{*/}
                                    {/*    <ArrowForwardIosIcon style={{color: color, transform: 'scale(0.6)', marginTop: 8}}/>*/}
                                    {/*}*/}
                                    <ListItemText style={{
                                        paddingTop: 10,
                                        paddingLeft: 17
                                    }}
                                                  primary={<span style={{
                                                      fontFamily: 'Varela Round',
                                                      fontSize: 13,
                                                      color: '#545454'
                                                  }}>HISTORIQUE</span>}
                                    />
                                </ListItem>
                            </Link>
                            <Divider/>
                        </List>
                    </Collapse>
                </>
                : path === 'regulations' ?
                    <>
                        <ListItem style={styleListItem} button
                                  onClick={() => {
                                      setOpenCollapse(prevState => !prevState);
                                  }}>
                            {
                                <ListItemIcon>
                                    {icon}
                                </ListItemIcon>
                            }
                            <ListItemText style={{
                                paddingTop: 10
                            }}
                                          primary={<span style={{
                                              fontFamily: 'Varela Round',
                                              fontSize: 13,
                                              color: '#545454'
                                          }}>{title}</span>}
                            />
                        </ListItem>
                        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Link to={'add_regulation'} style={noUnderLine}>
                                    <ListItem style={styleListItem && {paddingLeft: 54}} button
                                              onClick={() => {
                                                  props.onSelect(path)
                                              }}>
                                        <ListItemText style={{
                                            paddingTop: 10,
                                            paddingLeft: 17
                                        }}
                                                      primary={<span style={{
                                                          fontFamily: 'Varela Round',
                                                          fontSize: 13,
                                                          color: '#545454'
                                                      }}>{_TITLE}</span>
                                                      }
                                        />
                                    </ListItem>
                                </Link>
                                <Divider/>
                                <Link to={'list_regulations'} style={noUnderLine}>
                                    <ListItem style={styleListItem && {paddingLeft: 54}} button
                                              onClick={() => {
                                                  props.onSelect(path)
                                              }}>
                                        <ListItemText style={{
                                            paddingTop: 10,
                                            paddingLeft: 17
                                        }}
                                                      primary={<span style={{
                                                          fontFamily: 'Varela Round',
                                                          fontSize: 13,
                                                          color: '#545454'
                                                      }}>{listRegulationsTitle} </span>}
                                        />
                                    </ListItem>
                                </Link>
                                <Divider/>
                            </List>
                        </Collapse>
                    </> :
                    path === 'exclus' ?
                        <>
                            <ListItem style={styleListItem} button
                                      onClick={() => {
                                          setOpenCollapse(prevState => !prevState);
                                      }}>
                                {
                                    <ListItemIcon>
                                        {icon}
                                    </ListItemIcon>
                                }
                                <ListItemText style={{
                                    paddingTop: 10
                                }}
                                              primary={<span style={{
                                                  fontFamily: 'Varela Round',
                                                  fontSize: 13,
                                                  color: '#545454'
                                              }}>{title}</span>}
                                />
                        </ListItem>
                        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Link to={'excluded_users'} style={noUnderLine}>
                                    <ListItem style={styleListItem && {paddingLeft: 54}} button
                                              onClick={() => {
                                                  props.onSelect(path)
                                              }}>
                                        <ListItemText style={{
                                            paddingTop: 10,
                                            paddingLeft: 17
                                        }}
                                              primary={<span style={{
                                                  fontFamily: 'Varela Round',
                                                  fontSize: 13,
                                                  color: '#545454'
                                              }}>{_USERS}</span>
                                              }
                                        />
                                    </ListItem>
                                </Link>
                                <Divider/>
                                <Link to={'excluded_uos'} style={noUnderLine}>
                                    <ListItem style={styleListItem && {paddingLeft: 54}} button
                                              onClick={() => {
                                                  props.onSelect(path)
                                              }}>
                                        <ListItemText style={{
                                            paddingTop: 10,
                                            paddingLeft: 17
                                        }}
                                              primary={<span style={{
                                                  fontFamily: 'Varela Round',
                                                  fontSize: 13,
                                                  color: '#545454'
                                              }}>{_SITES}</span>}
                                        />
                                    </ListItem>
                                </Link>
                                <Divider/>
                            </List>
                        </Collapse>
                    </>
                    :
                    <Link to={path} style={noUnderLine}>
                        <ListItem className="routerLink" style={styleListItem} button onClick={() => {
                            props.onSelect(path)
                        }}>
                            <ListItemIcon>
                                {icon}
                            </ListItemIcon>
                            <ListItemText style={{
                                paddingTop: '10px'
                            }}
                                          primary={<span style={{
                                              fontFamily: 'Varela Round',
                                              fontSize: 13,
                                              color: '#545454'
                                          }}>{title}</span>}
                            />
                        </ListItem>
                    </Link>
        }
    </Fragment>
};


class ListItems extends React.Component {
    state = {selected: ""};
    onSelect = selected => {
        this.setState({selected});
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.lang !== this.props.lang) {
            this.forceUpdate();
        }
    }

    render() {
        const {_settings,_salary, _payEngine, _global, _launch, _periodicLaunch,
            _exclude, _IR, SERVERS, REGULATIONS,_modulation} = strings.title;
        const {_ramadanTitle} = strings;
        const {selected} = this.state;
        const {color} = this.props;

        let pages = [
            {
                title: _settings,
                icon: <AccountBalanceWalletIcon style={{color: color, marginTop: 5}}/>,
                path: "settings",
                permission: "#",
                authorities: ['ROLE_ADMIN_PAIE']
            },
            {
                title: _salary,
                icon: <AccountBalanceWalletIcon style={{color: color, marginTop: 5}}/>,
                path: "salary",
                permission: "#",
                authorities: []
            },
            {
                title: _modulation,
                icon: <AccountBalanceWalletIcon style={{color: color, marginTop: 5}}/>,
                path: "inject_file",
                permission: "#",
                authorities: ['ROLE_ADMIN_PAIE']
            },
            {
                title: _IR,
                icon: <AccountBalanceWalletIcon style={{color: color, marginTop: 5}}/>,
                path: "irs",
                permission: "#",
                authorities: ["ROLE_IRS_PT"]
            },
            {
                title: _payEngine,
                icon: <CreditCardIcon style={{color: color, marginTop: 5}}/>,
                path: "paie",
                permission: "#",
                authorities: ['ROLE_TMA_SALARY', 'ROLE_ADMIN_PAIE']
            },
            // {
            //     title: "DONNÉES VARIABLES", icon: <AssignmentIcon style={{color:color, marginTop: 5}}/>, path: "evs", permission: "#", authorities: ['ROLE_TMA_SALARY']
            // },
            // {
            //     title: _paramCompteur, icon: <ViewComfyIcon style={{color:color, marginTop: 5}}/>, path: "counters", permission: "#", authorities: ['ROLE_TMA_SALARY']
            // },
            // {
            //     title: _choixCompteur, icon: <ViewQuiltIcon style={{color:color, marginTop: 5}}/>, path: "counters_choice", permission: "#", authorities: ['ROLE_TMA_SALARY']
            // }, {
            //     title: _choixVariable, icon: <SettingsIcon style={{color:color, marginTop: 5}}/>, path: "variables_choice", permission: "#", authorities: ['ROLE_TMA_SALARY']
            // },
            {
                title: _launch,
                icon: <PagesIcon style={{color: color, marginTop: 5}}/>,
                path: "launcher",
                permission: "#",
                authorities: ['ROLE_TMA_SALARY', 'ROLE_ADMIN_PAIE']
            },
            {
                title: _exclude,
                icon: <DvrIcon style={{color: color, marginTop: 5}}/>,
                path: "exclus",
                permission: "#",
                authorities: ['ROLE_TMA_SALARY', 'ROLE_ADMIN_PAIE']
            },
            {
                title: _periodicLaunch,
                icon: <AlarmIcon style={{color: color, marginTop: 5}}/>,
                path: "periodic_launch",
                permission: "#",
                authorities: ['ROLE_PERIODIC_LAUNCH']
            },
            {
                title: REGULATIONS,
                icon: <ExtensionIcon style={{color: color, marginTop: 5}}/>,
                path: "regulations",
                permission: "#",
                authorities: ['ROLE_TMA_SALARY', 'ROLE_ADMIN_PAIE']
            },
            // {
            //     title: SERVERS,
            //     icon: <DnsIcon style={{color: color, marginTop: 5}}/>,
            //     path: "servers",
            //     permission: "#",
            //     authorities: ['ROLE_USER_SALARY', 'USER_SALARY']
            // },
            // {
            //     title: _modulation,
            //     icon: <SettingsIcon style={{color: color, marginTop: 5}}/>,
            //     path: "modulation",
            //     permission: "#",
            //     authorities: ['ROLE_TMA_SALARY']
            // },
            // {
            //     title: _global,
            //     icon: <TableChartIcon style={{color: color, marginTop: 5}}/>,
            //     path: "export",
            //     permission: "#",
            //     authorities: ['ROLE_TMA_SALARY']
            // }
            // {
            //     title: _extraction,
            //     icon: <DvrIcon style={{color: color, marginTop: 5}}/>,
            //     path: "extraction",
            //     permission: "#",
            //     authorities: ['ROLE_TMA_SALARY']
            // }
            // {
            //     title: _myshiftModifs, icon: <TableChartIcon style={{color:color, marginTop: 5}}/>, path: "myshiftModifications", permission: "#", authorities: ['ROLE_TMA_SALARY']
            // }
        ];

        return <List dense style={{maxHeight: 'calc(100vh - 140px)', paddingTop: 0}}>
            {pages && pages.map((page, index) => {
                const {title, icon, path, authorities} = page;
                if (title === _salary) {
                    return <NavItem
                        color={color}
                        key={index}
                        selected={selected}
                        onSelect={this.onSelect}
                        title={title}
                        icon={icon}
                        path={path}/>
                }
                if (title !== _salary && hasAuthority(authorities)) {
                    return <NavItem
                        key={index}
                        color={color}
                        selected={selected}
                        onSelect={this.onSelect}
                        title={title}
                        icon={icon}
                        path={path}/>
                }
            })}
        </List>
    }
}

const mapStateToProps = state => {
    return {
        lang: state.salaryReducer.lang
    };
};
export default connect(mapStateToProps)(ListItems);
