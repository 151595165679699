export const GET_REF_MODULATION_USER = 'GET_REF_MODULATION_USER';
export const GET_REF_MODULATION_USER_SUCCESS = 'GGET_REF_MODULATION_USER_SUCCESS';
export const GET_REF_MODULATION_USER_FAIL = 'GET_REF_MODULATION_USER_FAIL';
export const EXPORT_REF_MODULATION_USER = 'EXPORT_REF_MODULATION_USER';
export const EXPORT_REF_MODULATION_USER_SUCCESS = 'EXPORT_REF_MODULATION_USER_SUCCESS';
export const EXPORT_REF_MODULATION_USER_FAIL = 'EXPORT_REF_MODULATION_USER_FAIL';

export const GET_REF_WORKING_HOURS = 'GET_REF_WORKING_HOURS';
export const GET_REF_WORKING_HOURS_SUCCESS = 'GET_REF_WORKING_HOURS_SUCCESS';
export const GET_REF_WORKING_HOURS_FAIL = 'GET_REF_WORKING_HOURS_FAIL';
export const EXPORT_REF_WORKING_HOURS_USER = 'EXPORT_REF_WORKING_HOURS_USER';
export const EXPORT_REF_WORKING_HOURS_SUCCESS = 'EXPORT_REF_WORKING_HOURS_SUCCESS';
export const EXPORT_REF_WORKING_HOURS_FAIL = 'EXPORT_REF_WORKING_HOURS_FAIL';

export const GET_MODULATION = 'GET_MODULATION';
export const GET_MODULATION_SUCCESS = 'GET_MODULATION_SUCCESS';
export const GET_MODULATION_FAIL = 'GET_MODULATION_FAIL';
export const EXPORT_MODULATION = 'EXPORT_MODULATION';
export const EXPORT_MODULATION_SUCCESS = 'EXPORT_MODULATION_SUCCESS';
export const EXPORT_MODULATION_FAIL = 'EXPORT_MODULATION_FAIL';

export const GET_EXCLUDED_USERS = 'GET_EXCLUDED_USERS';
export const GET_EXCLUDED_USERS_SUCCESS = 'GET_EXCLUDED_USERS_SUCCESS';
export const GET_EXCLUDED_USERS_FAIL = 'GET_EXCLUDED_USERS_FAIL';
export const EXPORT_EXCLUDED_USERS = 'EXPORT_EXCLUDED_USERS';
export const EXPORT_EXCLUDED_USERS_SUCCESS = 'EXPORT_EXCLUDED_USERS_SUCCESS';
export const EXPORT_EXCLUDED_USERS_FAIL = 'EXPORT_EXCLUDED_USERS_FAIL';

export const GET_EXCLUDED_SITES = 'GET_EXCLUDED_SITES';
export const GET_EXCLUDED_SITES_SUCCESS = 'GET_EXCLUDED_SITES_SUCCESS';
export const GET_EXCLUDED_SITES_FAIL = 'GET_EXCLUDED_SITES_FAIL';
export const EXPORT_EXCLUDED_SITES = 'EXPORT_EXCLUDED_SITES';
export const EXPORT_EXCLUDED_SITES_SUCCESS = 'EXPORT_EXCLUDED_SITES_SUCCESS';
export const EXPORT_EXCLUDED_SITES_FAIL = 'EXPORT_EXCLUDED_SITES_FAIL';
