import React, {useEffect} from 'react';
import {Grid, Table, TableBody, TableHead, TableRow, Tooltip, withStyles} from "@material-ui/core";
import {ThemeContext} from "../../App";
import {connect, useDispatch} from "react-redux";
import {getAllServers} from "../../actions/LauncherActions";
import {CustomTableCell} from "../evs/EVsComponent";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import {styles} from "../../styles/CommonStyles";
import Loader from "../../layout/Loader";
import {strings} from "../../i18n/Strings";

const ServerComponent = ({classes, servers, loading}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllServers());
    }, []);

    const {TITLE, DISABLED, ENABLED} = strings.SERVERS;
    const {NAME, EXCEPTION, IP, STATUS} = strings.SERVERS.COLUMNS;

    const columns = [
        {headerName: NAME},
        {headerName: IP},
        {headerName: EXCEPTION},
        {headerName: STATUS},
    ];

    return (
        <ThemeContext.Consumer>
            {context => (
                <div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <h1 style={{
                                marginTop: 1,
                                color: context.selectedTheme.color,
                                fontWeight: 'normal'
                            }}>{TITLE}</h1>
                        </Grid>
                    </Grid>
                    <hr color="#f5f5f5"/>
                    <br/><br/>
                    <Grid container spacing={2}>
                        <Table className={classes.table}>
                            <TableHead style={{background: context.selectedTheme.color}}>
                                <TableRow>
                                    {columns && columns.map(column =>
                                        <CustomTableCell align="center"><span style={{
                                            fontSize: '13px',
                                            fontWeight: 'bold'
                                        }}>{column.headerName}</span></CustomTableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {servers && servers.map(row => (
                                    <TableRow className={classes.row} key={row.id}>
                                        <CustomTableCell align="center">{row.name}</CustomTableCell>
                                        <CustomTableCell align="center">{row.ip}</CustomTableCell>
                                        <CustomTableCell
                                            align="center">{row.hasError ? row.hasError : '-'}</CustomTableCell>
                                        <CustomTableCell align="center">
                                            {row.enabled ? (
                                                <Tooltip title={ENABLED}>
                                                    <RadioButtonCheckedIcon style={{cursor: 'pointer', color: '#8EAC50'}}/>
                                                </Tooltip>) : (
                                                <Tooltip title={DISABLED}>
                                                    <RadioButtonCheckedIcon style={{cursor: 'pointer', color: '#E76161'}}/>
                                                </Tooltip>
                                            )}

                                        </CustomTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>

                    <Loader color={context.selectedTheme.color} open={loading}/>

                </div>
            )}
        </ThemeContext.Consumer>
    );
};

const mapStateToProps = state => {
    return {
        servers: state.launcherReducer.servers,
        loading: state.launcherReducer.loading
    };
}

export default connect(mapStateToProps)(withStyles(styles)(ServerComponent));
