import React, {useContext, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {strings} from "../../i18n/Strings";
import {exportModulation, getModulation} from "../../actions/SettingsActions";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {InputAdornment} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {Pagination} from "@material-ui/lab";
import {useStyles} from "./style";
import {format, isValid, startOfDay} from "date-fns";
import {ThemeContext} from "../../App";
import Button from "@material-ui/core/Button";
import exportIcon from "../../assets/images/export.png";
import Loader from "../../layout/Loader";


const ModulationComponent = ({ loading, data, totalPages, fetchData, isExporting, exportDataExcel }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [matricule, setMatricule] = useState('');
    const [date, setDate] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const {_modulation,_export} = strings.setting;
    const context = useContext(ThemeContext);
    const classes = useStyles(context.selectedTheme);
    const {
        _updatedDate,
        _matricule,
        _counter,
        _year,
        _month
    } = strings.setting.columns;
    const columns = [
        { headerName: _matricule, dataKey: 'matricule' },
        { headerName: _year, dataKey: 'year' },
        { headerName: _month, dataKey: 'month' },
        { headerName: _counter, dataKey: 'counter' },
        { headerName: _updatedDate, dataKey: 'updated' },
    ];

    useEffect(() => {
        fetchData({matricule:matricule,month:month,year:year}, 0);
    }, [matricule,date]);

    const handleChangePage = async (event, newPage) => {
        setPage(newPage - 1);
        await fetchData({matricule:matricule,month:month,year:year}, newPage - 1);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDateChange = (date) => {
        if (date && isValid(date)) {
            console.log(date)
            setDate(date);
            setMonth(format(date, 'MM'));
            setYear(format(date, 'yyyy'));
        }
        else{
            setDate(null);
            setMonth(null);
            setYear(null);
        }
    };

    const exportData = () => {
        exportDataExcel({matricule:matricule,month:month,year:year});
    }

    return (
        <ThemeContext.Consumer>
            {context => (
                <div className={classes.root}>
                    <div className={classes.formContainer}>
                        <Typography variant="h6" className={classes.title}
                                    style={{
                                        color: context.selectedTheme.color}}
                        >
                            {_modulation}
                        </Typography>
                        <div className={classes.form}>
                            <TextField id="outlined-basic"
                                       InputProps={{
                                           className: classes.field,
                                           endAdornment: (
                                               <InputAdornment position="end">
                                                   <SearchIcon />
                                               </InputAdornment>
                                           )
                                       }}
                                       value={matricule}
                                       label={_matricule} variant="outlined"
                                       onChange={(e) => setMatricule(e.target.value)}
                            />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    views={["year", "month"]}
                                    InputProps={{
                                        className: classes.field,
                                    }}
                                    format="MM/yyyy"
                                    inputVariant="outlined"
                                    disableToolbar
                                    id="date-picker-inline"
                                    label={_month + "/" + _year}
                                    value={date}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'changer la date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <Button variant="contained" disabled={isExporting} className={classes.button} onClick={exportData}>
                                <img style={{position: 'relative', right: '1vh'}} src={exportIcon} width={20} alt="ss"/>
                                {_export}  .xlsx
                            </Button>
                        </div>
                    </div>

                    <Paper className={classes.paper}>
                        <TableContainer className={classes.tableContainer}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow >
                                        {columns.map((column, index) => (
                                            <TableCell className={classes.header} key={index}>{column.headerName}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row, rowIndex) => (
                                        <TableRow key={rowIndex} className={classes.tableRow}
                                        >
                                            {columns.map((column, colIndex) => (
                                                <TableCell
                                                    key={colIndex}
                                                    className={classes.cell}
                                                >
                                                    {row[column.dataKey]}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{marginTop: "10px",display:"flex",justifyContent:"end"}}>
                            <Pagination
                                count={totalPages}
                                page={page + 1}
                                onChange={handleChangePage}
                                color="primary"
                                shape="rounded"
                                variant="outlined"
                                className={
                                    classes.ul
                                }
                            />
                        </div>
                        <Loader color={context.selectedTheme.color} open={loading}/>
                    </Paper>
                </div>
            )}
        </ThemeContext.Consumer>

    );
};

const mapStateToProps = (state) => ({
    loading: state.settingReducer.loading,
    data: state.settingReducer.modulationData,
    isExporting: state.settingReducer.isExporting,
    totalPages: state.settingReducer.modulationPages,
    lang: state.salaryReducer.lang
});

const mapDispatchToProps = {
    fetchData: getModulation,
    exportDataExcel: exportModulation,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModulationComponent);
